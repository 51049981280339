import React, { useEffect, useState } from "react"
import { useFormik } from "formik"
import * as Yup from "yup"
import moment from "moment"
import { size } from "lodash"
import { Dropdown } from "primereact/dropdown"
import { Button } from "primereact/button"
import { InputText } from "primereact/inputtext"
import { useLibros } from "../../hooks"

export function AnalisisDeclaracionPage() {

    const [libroCompras, setLibroCompras] = useState(null);
    const [libroContribuyente, setLibroContribuyente] = useState(null);
    const [libroVentas, setLibroVentas] = useState(null);
    const [compras, setCompras] = useState([]);
    const [contribuyente, setContribuyente] = useState([]);
    const [ventas, setVentas] = useState([]);
    const [calcST, setCalcST] = useState(false);
    const [inputRemanente, setInputRemanente] = useState(0);
    const [inputExcedente, setInputExcedente] = useState(0);
    const [inputRemanentePagoCuenta, setInputRemanentePagoCuenta] = useState(0);

    const { getClientesForDropdown, clientesDrop, getLibrosAnalisis, getLibroDetalleFormatR, loadingLibro } = useLibros();

    const meses = [
        { name: "Enero", value: "0" },
        { name: "Febrero", value: "1" },
        { name: "Marzo", value: "2" },
        { name: "Abril", value: "3" },
        { name: "Mayo", value: "4" },
    ]

    const formik = useFormik({
        initialValues: initialValues(),
        validationSchema: Yup.object(validationSchema()),
        onSubmit: async (formValue) => {
            const date = new Date()
            const firstDay = new Date(formValue.Year, parseInt(formValue.Mes), 1)
            const lastDay = new Date(formValue.Year, parseInt(formValue.Mes) + 1, 0)

            const list_libros = await getLibrosAnalisis(formValue.Cliente, moment(firstDay).format("YYYY-MM-DD"), moment(lastDay).format("YYYY-MM-DD"))

            for await (const libro of list_libros) {
                const data_book = await getLibroDetalleFormatR(libro.id)

                switch (libro.TipoLibro) {
                    case 1:
                        setLibroCompras(data_book)
                        break;

                    case 2:
                        setLibroContribuyente(data_book)
                        break;

                    case 3:
                        setLibroVentas(data_book)
                        break;

                    default:
                        break;
                }
            }

            setCalcST((e) => !e)

        }
    })

    useEffect(() => {
        if (libroCompras != null) {

            if (size(libroCompras) > 0) {

                let gravadas = 0;
                let iva = 0;
                let retencion = 0;

                for (const libro of libroCompras) {
                    if (libro.Internas != null) {
                        gravadas += parseFloat(libro.Internas)
                    }
                    if (libro.Retencion != null) {
                        retencion += parseFloat(libro.Retencion)
                    }
                    iva += parseFloat(libro.IVA)
                }

                setCompras({
                    gravadas: gravadas.toFixed(2),
                    iva: iva.toFixed(2),
                    total: (gravadas + iva).toFixed(2),
                    retencion: retencion.toFixed(2),
                })
            }
            if (size(libroContribuyente) > 0) {

                let gravadas = 0;
                let iva = 0;

                for (const libro of libroContribuyente) {
                    gravadas += parseFloat(libro.Ventas_Gravadas)
                    iva += parseFloat(libro.Debito_Fiscal)
                }

                setContribuyente({
                    gravadas: gravadas.toFixed(2),
                    iva: iva.toFixed(2),
                    total: (gravadas + iva).toFixed(2),
                })
            }
            if (size(libroVentas) > 0) {

                let gravadas = 0;
                let iva = 0;

                for (const libro of libroVentas) {

                    let partial = (parseFloat(libro.Locales) / 1.13)

                    gravadas += partial
                    iva += partial * 0.13
                }

                setVentas({
                    gravadas: gravadas.toFixed(2),
                    iva: iva.toFixed(2),
                    total: (gravadas + iva).toFixed(2),
                })
            }
        }
    }, [calcST])

    useEffect(() => {
        (async () => {
            await getClientesForDropdown();
        })()
    }, [])

    return (
        <>
            <div className="grid">
                <div className="col-12">
                    <div className="card">

                        <h3>Analisis de Pago de Renta</h3>
                        <p>Ingrese el remanente de su cliente para poder hacer un calculo mas exacto</p>

                        <form onSubmit={formik.handleSubmit}>
                            <div className="grid">
                                <div className="col-4">
                                    <label htmlFor="Cliente">Cliente</label>
                                    <Dropdown id="Cliente" name="Cliente" className="w-full" filter options={clientesDrop} optionLabel="name" value={formik.values.Cliente} onChange={(e) => formik.setFieldValue("Cliente", e.value)} />
                                </div>
                                <div className="col-4">
                                    <label htmlFor="Mes">Mes</label>
                                    <Dropdown id="Mes" name="Mes" className="w-full" options={meses} optionLabel="name" value={formik.values.Mes} onChange={(e) => formik.setFieldValue("Mes", e.value)} />
                                </div>
                                <div className="col-4">
                                    <label htmlFor="Year">Año</label>
                                    <InputText id="Year" name="Year" className="w-full" value={formik.values.Year} onChange={formik.handleChange} />
                                </div>
                            </div>
                            <div className="grid">
                                <div className="col-12 text-right">
                                    <Button type="submit" severity="info">Realizar Analisis</Button>
                                </div>
                            </div>
                        </form>

                    </div>
                </div>
            </div>

            <div className="grid">
                <div className="col-12">
                    <div className="card">

                        {loadingLibro ? (
                            <div className="grid">
                                <div className="col-12 text-center">
                                    <i className="pi pi-spin pi-spinner" style={{ fontSize: '5rem' }}></i>
                                </div>
                            </div>
                        ) : (
                            <div className="grid">
                                <div className="col-12">

                                    <h3>Resultado de Analisis</h3>

                                    <div className="grid font-bold">

                                        <div className="col-3">
                                            VENTAS
                                        </div>
                                        <div className="col-3">
                                            TOTAL
                                        </div>
                                        <div className="col-3">
                                            DEBITO
                                        </div>
                                        <div className="col-3">
                                            PAGO A CUENTA
                                        </div>

                                    </div>
                                    <div className="grid">

                                        <div className="col-3">
                                            VENTAS A CONTRIBUYENTES
                                        </div>
                                        <div className="col-3">
                                            {contribuyente.gravadas}
                                        </div>
                                        <div className="col-3">
                                            {contribuyente.iva}
                                        </div>
                                        <div className="col-3">
                                        </div>

                                    </div>
                                    <div className="grid">

                                        <div className="col-3">
                                            VENTAS A CONSUMIDOR FINAL
                                        </div>
                                        <div className="col-3">
                                            {ventas.gravadas}
                                        </div>
                                        <div className="col-3">
                                            {ventas.iva}
                                        </div>
                                        <div className="col-3">
                                        </div>

                                    </div>
                                    <hr />
                                    <div className="grid font-bold">

                                        <div className="col-3">
                                            TOTAL VENTA
                                        </div>
                                        <div className="col-3">
                                            {(parseFloat(contribuyente.gravadas) + parseFloat(ventas.gravadas)).toFixed(2)}
                                        </div>
                                        <div className="col-3">
                                            {(parseFloat(contribuyente.iva) + parseFloat(ventas.iva)).toFixed(2)}
                                        </div>
                                        <div className="col-3">
                                            {((parseFloat(contribuyente.gravadas) + parseFloat(ventas.gravadas)) * 0.0175).toFixed(2)}
                                        </div>

                                    </div>
                                    <hr />
                                    <hr />
                                    <div className="grid font-bold">

                                        <div className="col-3">
                                            COMPRAS
                                        </div>
                                        <div className="col-3">
                                            TOTAL
                                        </div>
                                        <div className="col-3">

                                        </div>
                                        <div className="col-3">

                                        </div>

                                    </div>
                                    <div className="grid">

                                        <div className="col-3">
                                            EXENTAS
                                        </div>
                                        <div className="col-3">
                                            -
                                        </div>
                                        <div className="col-3">

                                        </div>
                                        <div className="col-3">
                                        </div>

                                    </div>
                                    <div className="grid">

                                        <div className="col-3">
                                            INTERNAS GRAVADAS
                                        </div>
                                        <div className="col-3">
                                            {compras.gravadas}
                                        </div>
                                        <div className="col-3">
                                            {compras.iva}
                                        </div>
                                        <div className="col-3">
                                        </div>

                                    </div>
                                    <div className="grid font-bold">

                                        <div className="col-3">
                                            TOTAL COMPRAS
                                        </div>
                                        <div className="col-3">
                                            {compras.gravadas}
                                        </div>
                                        <div className="col-3">
                                            {compras.iva}
                                        </div>
                                        <div className="col-3">
                                        </div>

                                    </div>

                                    <div className="grid">
                                        <div className="col-3">
                                            REMANENTE
                                        </div>
                                        <div className="col-3">
                                        </div>
                                        <div className="col-3">
                                            <span className="p-float-label">

                                                <InputText id="Remanente" name="Remanente" value={inputRemanente} onChange={(e) => setInputRemanente(e.target.value)} />
                                                <label htmlFor="Remanente">Remanente</label>

                                            </span>
                                        </div>
                                        <div className="col-3">
                                            <span className="p-float-label">

                                                <InputText id="RemanentePagoCuenta" name="RemanentePagoCuenta" value={inputRemanentePagoCuenta} onChange={(e) => setInputRemanentePagoCuenta(e.target.value)} />
                                                <label htmlFor="RemanentePagoCuenta">RemanentePagoCuenta</label>

                                            </span>
                                        </div>
                                    </div>

                                    <div className="grid">
                                        <div className="col-3">
                                            TOTAL CREDITO
                                        </div>
                                        <div className="col-3">
                                        </div>
                                        <div className="col-3">
                                            {(parseFloat(compras.iva) + parseFloat(inputRemanente)).toFixed(2)}
                                        </div>
                                        <div className="col-3">
                                        </div>
                                    </div>

                                    <div className="grid font-bold">
                                        <div className="col-3">
                                            REMANENTE (-) A PAGAR (+)
                                        </div>
                                        <div className="col-3">
                                        </div>
                                        <div className="col-3">

                                            {((parseFloat(contribuyente.iva) + parseFloat(ventas.iva)) - (parseFloat(compras.iva) + parseFloat(inputRemanente))).toFixed(2)}
                                        </div>
                                        <div className="col-3">
                                        </div>
                                    </div>
                                    <hr />
                                    <hr />

                                    <div className="grid">
                                        <div className="col-3">
                                            Anticipo a Cuenta IVA del 2% efectuada al Declarante
                                        </div>
                                        <div className="col-3">
                                        </div>
                                        <div className="col-3">
                                            -
                                        </div>
                                        <div className="col-3">
                                        </div>
                                    </div>
                                    <div className="grid">
                                        <div className="col-3">
                                            Retencion 1% IVA efectuado al declarante (VENTA)
                                        </div>
                                        <div className="col-3">
                                        </div>
                                        <div className="col-3">
                                            -
                                        </div>
                                        <div className="col-3">
                                        </div>
                                    </div>
                                    <div className="grid">
                                        <div className="col-3">
                                            Percepcion 1% IVA efectuado al declarante (COMPRA)
                                        </div>
                                        <div className="col-3">
                                        </div>
                                        <div className="col-3">
                                            {compras.retencion}
                                        </div>
                                        <div className="col-3">
                                        </div>
                                    </div>
                                    <div className="grid">
                                        <div className="col-3">
                                            Excedente de Impuesto Período Anterior
                                        </div>
                                        <div className="col-3">
                                        </div>
                                        <div className="col-3">
                                            <span className="p-float-label">

                                                <InputText id="Excedente" name="Excedente" value={inputExcedente} onChange={(e) => setInputExcedente(e.target.value)} />
                                                <label htmlFor="Excedente">Excedente</label>

                                            </span>
                                        </div>
                                        <div className="col-3">
                                        </div>
                                    </div>

                                    <div className="grid font-bold">
                                        <div className="col-3">
                                            A FAVOR DEL DECLARANTE
                                        </div>
                                        <div className="col-3">
                                        </div>
                                        <div className="col-3">
                                            {(parseFloat(compras.retencion) + parseFloat(inputExcedente)).toFixed(2)}
                                        </div>
                                        <div className="col-3">
                                        </div>
                                    </div>
                                    <div className="grid">
                                        <div className="col-3">
                                            RETENCIONES
                                        </div>
                                        <div className="col-3">
                                        </div>
                                        <div className="col-3">
                                            -
                                        </div>
                                        <div className="col-3">
                                        </div>
                                    </div>

                                    <div className="grid font-bold">
                                        <div className="col-3">
                                            A PAGAR (+) REMANENTE (-)
                                        </div>
                                        <div className="col-3">
                                        </div>
                                        <div className="col-3">

                                            {((parseFloat(contribuyente.iva) + parseFloat(ventas.iva)) - (parseFloat(compras.iva) + parseFloat(inputRemanente))) < 0 ? ((parseFloat(contribuyente.iva) + parseFloat(ventas.iva)) - (parseFloat(compras.iva) + parseFloat(inputRemanente))).toFixed(2) : (((parseFloat(contribuyente.iva) + parseFloat(ventas.iva)) - (parseFloat(compras.iva) + parseFloat(inputRemanente))) - (parseFloat(compras.retencion) + parseFloat(inputExcedente))).toFixed(2)}

                                        </div>
                                        <div className="col-3">
                                            {(((parseFloat(contribuyente.gravadas) + parseFloat(ventas.gravadas)) * 0.0175) + parseFloat(inputRemanentePagoCuenta)).toFixed(2)}
                                        </div>
                                    </div>

                                    <div className="grid font-bold">
                                        <div className="col-3">

                                        </div>
                                        <div className="col-3">
                                            IVA
                                        </div>
                                        <div className="col-3">
                                        {((parseFloat(contribuyente.iva) + parseFloat(ventas.iva)) - (parseFloat(compras.iva) + parseFloat(inputRemanente))) < 0 ? ((parseFloat(contribuyente.iva) + parseFloat(ventas.iva)) - (parseFloat(compras.iva) + parseFloat(inputRemanente))).toFixed(2) : (((parseFloat(contribuyente.iva) + parseFloat(ventas.iva)) - (parseFloat(compras.iva) + parseFloat(inputRemanente))) - (parseFloat(compras.retencion) + parseFloat(inputExcedente))).toFixed(2)}
                                        </div>
                                        <div className="col-3">
                                        </div>
                                    </div>
                                    <div className="grid font-bold">
                                        <div className="col-3">
                                        </div>
                                        <div className="col-3">
                                            PAGO A CUENTA
                                        </div>
                                        <div className="col-3">
                                            {(((parseFloat(contribuyente.gravadas) + parseFloat(ventas.gravadas)) * 0.0175) + parseFloat(inputRemanente)).toFixed(2)}
                                        </div>
                                        <div className="col-3">
                                        </div>
                                    </div>
                                    <div className="grid font-bold">
                                        <div className="col-3">

                                        </div>
                                        <div className="col-3">
                                            TOTAL A PAGAR
                                        </div>
                                        <div className="col-3">
                                        {((parseFloat(contribuyente.iva) + parseFloat(ventas.iva)) - (parseFloat(compras.iva) + parseFloat(inputRemanente))) < 0 ? ((parseFloat(contribuyente.iva) + parseFloat(ventas.iva)) - (parseFloat(compras.iva) + parseFloat(inputRemanente))).toFixed(2) : (((parseFloat(contribuyente.iva) + parseFloat(ventas.iva)) - (parseFloat(compras.iva) + parseFloat(inputRemanente))) - (parseFloat(compras.retencion) + parseFloat(inputExcedente))).toFixed(2)}
                                        </div>
                                        <div className="col-3">
                                        </div>
                                    </div>

                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </>
    )
}

function initialValues() {
    return {
        Cliente: "",
        Mes: "",
        Year: "",
    }
}

function validationSchema() {
    return {
        Cliente: Yup.string().required(true),
        Mes: Yup.string().required(true),
        Year: Yup.string().required(true),
    }
}
import React, { useEffect, useRef, useState } from "react"
import { filter, size } from "lodash"
import { FileUpload } from 'primereact/fileupload';
import { DataTable } from "primereact/datatable"
import { Column } from "primereact/column"
import { Dropdown } from "primereact/dropdown"
import { useFormik } from "formik"
import * as Yup from "yup"
import { useLibros } from "../../hooks"
import { Button } from "primereact/button";

export function LoadJsonPage() {

    const [listJson, setListJson] = useState([])
    const [loadingPage, setLoadingPage] = useState(false)
    const fileUpload = useRef(null)

    const { getClientesForDropdown, clientesDrop, getLibrosForDropDown, librosDrop, getLibroDetalleFormatR, addLibroDetalle } = useLibros();

    const formik = useFormik({
        initialValues: initialValues(),
        validationSchema: Yup.object(validationSchema()),
        onSubmit: async (formValue) => {

            const fila_group = await getLibroDetalleFormatR(formValue.Libro)
            let onFilaGroup = size(fila_group) + 1;

            setLoadingPage(true)

            for await (const documento of listJson) {

                let data_save = {
                    FilaGroup: onFilaGroup,
                    Libro: formValue.Libro,
                }

                if (documento.documento == "Credito Fiscal"){

                    for await (const column of Object.keys(documento)) {
    
                        const value_insert = documento[column]
                        let bandera = true;
    
                        switch (column) {
                            case "cliente":
                            case "documento":
                                bandera = false;
                                break;
                            case "comprovante":
                                data_save["Columna"] = 12
                                break;
                            case "fecha":
                                data_save["Columna"] = 11
                                break;
                            case "subtotal":
                                data_save["Columna"] = 14
                                break;
                            case "total":
                                data_save["Columna"] = 18
                                break;
                            case "iva":
                                data_save["Columna"] = 16
                                break;
                            case "proveedor":
                                data_save["Columna"] = 13
                                break;
                            case "registro":
                                data_save["Columna"] = 3
                                break;
    
                            default:
                                break;
                        }
    
                        if (bandera) {
                            data_save["Valor"] = value_insert;
                            await addLibroDetalle(data_save);
                            // console.log(data_save);
                        }
    
                    }
    
                    let data_save_corr = {
                        FilaGroup: onFilaGroup,
                        Libro: formValue.Libro,
                        Columna: 1,
                        Valor: onFilaGroup,
                    }
    
                    await addLibroDetalle(data_save_corr);
    
    
                    onFilaGroup += 1;
                }


            }

            setLoadingPage(false)
        }
    })

    useEffect(() => {
        (async () => {
            await getClientesForDropdown();
        })()
    }, [])

    const getLibroDependiente = async (e) => {
        formik.setFieldValue("Cliente", e.value)
        await getLibrosForDropDown(e.value, 1)
    }

    const onLoadJson = async (data) => {

        let list_final = []

        for await (const file_data of data.files) {
            const reader = new FileReader()

            reader.onload = (e) => {
                try {
                    const parsedData = JSON.parse(e.target.result);

                    if ("identificacion" in parsedData) {

                        if ("tipoDte" in parsedData.identificacion) {

                            let tipo_documento = ""

                            switch (parseInt(parsedData.identificacion.tipoDte)) {
                                case 1:
                                    tipo_documento = "Consumidor Final"
                                    break;
                                case 3:
                                    tipo_documento = "Credito Fiscal"
                                    break;

                            }

                            let detail_list = {
                                cliente: parsedData.receptor.nombre,
                                documento: tipo_documento,
                                fecha: parsedData.identificacion.fecEmi,
                                comprovante: parsedData.identificacion.numeroControl,
                                registro: parsedData.emisor.nrc,
                                proveedor: parsedData.emisor.nombre,
                                subtotal: parsedData.resumen.subTotal,
                                total: parsedData.resumen.totalPagar
                            }

                            let iva_data = 0;

                            if ("tributos" in parsedData.resumen) {

                                iva_data = filter(parsedData.resumen.tributos, ["codigo", "20"])
                                if (size(iva_data) > 0) {
                                    iva_data = iva_data[0].valor;
                                } else {
                                    iva_data = 0;
                                }


                            }

                            detail_list["iva"] = iva_data

                            setListJson((e) => [...e, detail_list])

                        }

                    } else {
                        console.log(parsedData)
                    }

                } catch (error) {
                    console.error("Error parsing JSON:", error);
                }
            }

            reader.readAsText(file_data)

        }

        fileUpload.current.clear()

    }

    return (
        <>
            <div className="grid">
                <div className="col-12">
                    <div className="card">
                        <h5>Importar Archivos JSON</h5>
                        <p>Por favor seleccione los archivos JSON a importar</p>
                        <form onSubmit={formik.handleSubmit}>

                            <div className="grid">
                                <div className="col-6">

                                    <FileUpload ref={fileUpload} name="demo[]" url="#" customUpload uploadHandler={onLoadJson} multiple accept=".json" maxFileSize={1000000} emptyTemplate={<p className="m-0">Seleccione todos los JSON a Importar</p>} />

                                </div>
                                <div className="col-6">
                                    <div className="grid my-3">
                                        <div className="col-12">
                                            <span className="p-float-label">
                                                <Dropdown id="Cliente" name="Cliente" className="w-full" filter options={clientesDrop} optionLabel="name" value={formik.values.Cliente} onChange={(e) => getLibroDependiente(e)} />
                                                <label htmlFor="Cliente">Cliente</label>
                                            </span>

                                        </div>
                                    </div>
                                    <div className="grid my-3">
                                        <div className="col-12">
                                            <span className="p-float-label">
                                                <Dropdown id="Libro" name="Libro" className="w-full" filter options={librosDrop} optionLabel="name" value={formik.values.Libro} onChange={(e) => formik.setFieldValue("Libro", e.value)} />
                                                <label htmlFor="Libro">Libro</label>
                                            </span>

                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="grid">
                                <div className="col-12 text-right">
                                    <Button severity="success" type="submit" size="large">Guardar</Button>
                                </div>
                            </div>
                        </form>

                    </div>
                </div>
            </div>

            {loadingPage && (
                <div className="grid">
                    <div className="col-12 text-center">
                        <i className="pi pi-spin pi-spinner" style={{fontSize: '5rem'}}></i>
                    </div>
                </div>
            )}

            <div className="grid">
                <div className="col-12">
                    <div className="card">
                        <h5>Resultado Importacion</h5>
                        <p>Seleccione los archivos a guardar y el cliente y libro donde seran guardados</p>
                        <DataTable value={listJson}>
                            <Column field="cliente" header="cliente"></Column>
                            <Column field="documento" filter header="documento"></Column>
                            <Column sortable filter field="fecha" header="fecha"></Column>
                            <Column field="comprovante" header="comprovante"></Column>
                            <Column field="registro" header="registro"></Column>
                            <Column field="proveedor" header="proveedor"></Column>
                            <Column field="subtotal" header="subtotal"></Column>
                            <Column field="iva" header="iva"></Column>
                            <Column field="total" header="total"></Column>
                        </DataTable>
                    </div>
                </div>
            </div>
        </>
    )
}

function initialValues() {
    return {
        Cliente: "",
        Libro: "",
    }
}

function validationSchema() {
    return {
        Cliente: Yup.string().required(true),
        Libro: Yup.string().required(true),
    }
}
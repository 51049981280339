import React, {useEffect, useState} from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Dropdown } from "primereact/dropdown"
import {Button} from "primereact/button"
import { useLibros } from "../../hooks"

export function FiltroEmpleado({ onResponse }) {

    const [blockLibro, setBlockLibro] = useState(true);
    const [mesSeleccionado, setMesSeleccionado] = useState(0)

    const { librosDrop, getClientesForDropdown, clientesDrop, getLibrosForDropDown, getLibroDetalleFormat, libroDetalles, getDatosLibro } = useLibros();

    useEffect(() => {
        (async () => {
            await getClientesForDropdown()
        })()
    }, [])

    const formik = useFormik({
        initialValues: initialValues(),
        validationSchema: Yup.object(validationSchema()),
        onSubmit: (formValue) => {
            onResponse(formValue)
        }
    });

    const getLibroDependiente = async (e) => {
        formik.setFieldValue("Cliente", e.value)
        await getLibrosForDropDown(e.value, 1)
        setBlockLibro(true)
    }

    const getLibroDetalleDependiente = async (e) => {
        formik.setFieldValue("Libro", e.value)
        setMesSeleccionado(await getDatosLibro(e.value))
        await getLibroDetalleFormat(e.value)
        setBlockLibro(false)
    }

    return (
        <form onSubmit={formik.handleSubmit}>

            <div className="grid">
                <div className="col-6">
                    <span className="p-float-label">
                        <Dropdown id="Cliente" name="Cliente" className="w-full" filter options={clientesDrop} optionLabel="name" value={formik.values.Cliente} onChange={(e) => getLibroDependiente(e)} />
                        <label htmlFor="Cliente">Cliente</label>
                    </span>
                </div>
                <div className="col-6">
                    <span className="p-float-label">
                        <Dropdown id="Libro" name="Libro" className="w-full" filter options={librosDrop} optionLabel="name" value={formik.values.Libro} onChange={(e) => getLibroDetalleDependiente(e)} />
                        <label htmlFor="Libro">Libro</label>
                    </span>
                </div>
            </div>

            <div className="grid">
                <div className="col-12 text-right">
                    <Button severity="primary" type="submit" size="large">Generar Partidas</Button>
                </div>
            </div>

        </form>
    )
}

function initialValues() {
    return {
        Cliente: "",
        Libro: "",
    }
}

function validationSchema() {
    return {
        Cliente: "",
        Libro: "",
    }
}
import React, { useEffect, useRef, useState } from "react";
import {NumericFormat} from "react-number-format"
import { useFormik } from "formik"
import * as Yup from "yup"
import { size } from "lodash"
import moment from "moment"
import { evaluate } from "mathjs"
import { InputText } from "primereact/inputtext"
import { Dropdown } from "primereact/dropdown"
import { InputSwitch } from "primereact/inputswitch"
import { Button } from "primereact/button";
import { DataTable } from "primereact/datatable"
import { ColumnGroup } from "primereact/columngroup";
import { Column } from "primereact/column"
import { Row } from "primereact/row"
import { BlockUI } from "primereact/blockui"
import { useLibros } from "../../hooks"

export function LibroConsumidorFinalPage() {
    const [blockLibro, setBlockLibro] = useState(true);
    const [SwitchUno, setSwitchUno] = useState(false);
    const [mesSeleccionado, setMesSeleccionado] = useState(0)
    const [fechaInicio, setFechaInicio] = useState(0)
    const [fechaFinal, setFechaFinal] = useState(0)

    const FechaRef = useRef(null);
    const DelRef = useRef(null);
    const AlRef = useRef(null);
    const LocalesRef = useRef(null);

    const { getProveedorByNrc, librosDrop, getClientesForDropdown, clientesDrop, getLibrosForDropDown, getLibroDetalleFormat, libroDetalles, addLibroDetalle, getDatosLibro, getResolucionCliente } = useLibros();

    const formik = useFormik({
        initialValues: initialValues(),
        validationSchema: Yup.object(validationSchema()),
        onSubmit: async (formValue) => {
            let data_save = {
                FilaGroup: size(libroDetalles) + 1,
                Libro: formValue.Libro,
            }

            for await (const value of Object.keys(formValue)) {
                const value_insert = formValue[value]
                let bandera = true;

                switch (value) {
                    case "Cliente":
                    case "Libro":
                        bandera = false;
                        break;
                    case "Fecha":
                        data_save["Columna"] = 11
                        break;
                    case "Del":
                        data_save["Columna"] = 19
                        break;
                    case "Al":
                        data_save["Columna"] = 20
                        break;
                    case "Locales":
                        data_save["Columna"] = 22
                        break;

                    default:
                        break;
                }
                if (bandera) {
                    if (value === "Fecha") {

                        data_save["Valor"] = moment(value_insert).format("YYYY-MM-DD")
                    } else if (value === "Locales") {

                        data_save["Valor"] = (evaluate(value_insert)).toFixed(2)
                    } else {

                        data_save["Valor"] = value_insert
                    }
                    addLibroDetalle(data_save)

                }
            }

            if (SwitchUno) {
                data_save["Columna"] = 17
                data_save["Valor"] = (evaluate(formValue.Locales) * 0.01).toFixed(2)
                await addLibroDetalle(data_save)

                data_save["Columna"] = 10
                data_save["Valor"] = (evaluate(formValue.Locales) * 1.01).toFixed(2)
                await addLibroDetalle(data_save)
            } else {
                data_save["Columna"] = 10
                data_save["Valor"] = (evaluate(formValue.Locales)).toFixed(2)
                await addLibroDetalle(data_save)
            }

            await getLibroDetalleFormat(formValue.Libro)
            clearFields();
        }
    })

    useEffect(() => {
        (async () => {
            await getClientesForDropdown()
        })()
    }, [])

    useEffect(() => {
        if (mesSeleccionado != 0) {
            let fecha_libro = new Date(mesSeleccionado[0].Fecha)

            let startDate = new Date(fecha_libro.getFullYear(), fecha_libro.getMonth() + 1, 1)
            let endDate = new Date(fecha_libro.getFullYear(), fecha_libro.getMonth() + 2, 0)

            setFechaInicio(startDate)
            setFechaFinal(endDate)
        }
    }, [mesSeleccionado])

    const clearFields = async () => {

        formik.setFieldValue("Del", "")
        formik.setFieldValue("Al", "")
        formik.setFieldValue("Locales", "")

    }

    const getLibroDependiente = async (e) => {
        formik.setFieldValue("Cliente", e.value)
        await getLibrosForDropDown(e.value, 3)
        setBlockLibro(true)
    }

    const getLibroDetalleDependiente = async (e) => {
        formik.setFieldValue("Libro", e.value)
        setMesSeleccionado(await getDatosLibro(e.value))
        await getLibroDetalleFormat(e.value)
        setBlockLibro(false)
    }

    const LocalesTotal = () => {
        let total = 0

        if (size(libroDetalles) > 0) {

            for (const value of libroDetalles) {
                if (value.Locales != null) {

                    total += parseFloat(value.Locales)
                }
            }
        }

        return (
            <NumericFormat prefix="$" thousandSeparator displayType="text" decimalSeparator="." value={total.toFixed(2)} />
        )
    }
    const RetencionTotal = () => {
        let total = 0

        if (size(libroDetalles) > 0) {

            for (const value of libroDetalles) {
                if (value.Retencion != null) {

                    total += parseFloat(value.Retencion)
                }
            }
        }

        return (
            <NumericFormat prefix="$" thousandSeparator displayType="text" decimalSeparator="." value={total.toFixed(2)} />
        )
    }
    const Venta_TotalTotal = () => {
        let total = 0

        if (size(libroDetalles) > 0) {

            for (const value of libroDetalles) {
                if (value.Venta_Total != null) {

                    total += parseFloat(value.Venta_Total)
                }
            }
        }

        return (
            <NumericFormat prefix="$" thousandSeparator displayType="text" decimalSeparator="." value={total.toFixed(2)} />
        )
    }

    const exportExcel = async () => {

        let config_format_ap = []
        
        for (const libro of libroDetalles){
            
            let ap_data = {
                Fecha: libro.Fecha,
                Del_Numero: libro.Del_Numero,
                Al_Numero: libro.Al_Numero,
                Locales: libro.Locales,
                Retencion: libro.Retencion,
                Venta_Total: libro.Venta_Total,
            }

            config_format_ap.push(ap_data)

        }
        
        import('xlsx').then((xlsx) => {
            const worksheet = xlsx.utils.json_to_sheet(config_format_ap);
            const workbook = { Sheets: { data: worksheet }, SheetNames: ['data'] };
            const excelBuffer = xlsx.write(workbook, {
                bookType: 'xlsx',
                type: 'array'
            });

            saveAsExcelFile(excelBuffer, 'LibroCompras');
        });
    };

    const saveAsExcelFile = (buffer, fileName) => {
        import('file-saver').then((module) => {
            if (module && module.default) {
                let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
                let EXCEL_EXTENSION = '.xlsx';
                const data = new Blob([buffer], {
                    type: EXCEL_TYPE
                });

                module.default.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
            }
        });
    };

    const exportCSV = async (selectionOnly) => {

        const response_resolucion = await getResolucionCliente(formik.values.Cliente);

        let csvContent = "data:text/csv;charset=utf-8,";

        for await (const data of libroDetalles){
            csvContent += `${moment(data.Fecha).format("DD/MM/YYYY")};1;01;${response_resolucion.Resolucion};${response_resolucion.Serie};${data.Del_Numero};${data.Al_Numero};${data.Del_Numero};${data.Al_Numero};;0.00;0.00;0.00;${(data.Locales == null) ? '0.00' : data.Locales};0.00;0.00;0.00;0.00;0.00;${(data.Venta_Total == null) ? '0.00' : data.Venta_Total};2\n`;
        }

        const encodedUri = encodeURI(csvContent);
        const link = document.createElement("a");
        link.setAttribute("href", encodedUri);
        link.setAttribute("download", `ConsumidorFinal-${response_resolucion.NombreCliente}.csv`);
        document.body.appendChild(link); // Required for Firefox
        link.click();
        
    };


    const header = (
        <div className="flex align-items-center justify-content-end gap-2">
            <Button type="button" icon="pi pi-file-excel" severity="success" rounded onClick={exportExcel} data-pr-tooltip="XLS" />
            <Button type="button" icon="pi pi-file" rounded onClick={() => exportCSV(false)} data-pr-tooltip="CSV" />
        </div>
    );

    const footerGroup = (
        <ColumnGroup>
            <Row>
                <Column footer="Totals:" colSpan={3} footerStyle={{ textAlign: 'right' }} />
                <Column footer={LocalesTotal} />
                <Column footer={RetencionTotal} />
                <Column footer={Venta_TotalTotal} />
            </Row>
        </ColumnGroup>
    );

    const validDateBook = (e) => {
        let value_date = moment(e.target.value);

        if (value_date >= fechaInicio && value_date <= fechaFinal) {
            console.log("pass")
        } else {
            formik.setFieldValue("Fecha", "")
            FechaRef.current.focus()
        }
    }

    return (
        <>
            <div className="grid">
                <div className="col-12">
                    <div className="card">
                        <h5>Libro de Ventas a Consumidor Final</h5>
                        <p>Ingresa tus documentos en el formulario</p>
                        <form onSubmit={formik.handleSubmit}>

                            <div className="grid my-4">
                                <div className="col-6">
                                    <span className="p-float-label">
                                        <Dropdown id="Cliente" name="Cliente" className="w-full" filter options={clientesDrop} optionLabel="name" value={formik.values.Cliente} onChange={(e) => getLibroDependiente(e)} />
                                        <label htmlFor="Cliente">Cliente</label>
                                    </span>
                                </div>
                                <div className="col-6">
                                    <span className="p-float-label">
                                        <Dropdown id="Libro" name="Libro" className="w-full" filter options={librosDrop} optionLabel="name" value={formik.values.Libro} onChange={(e) => getLibroDetalleDependiente(e)} />
                                        <label htmlFor="Libro">Libro</label>
                                    </span>
                                </div>
                            </div>

                            <BlockUI blocked={blockLibro}>
                                <div className="grid my-3">
                                    <div className="col-3">
                                        <span className="p-float-label">
                                            {/* <Calendar ref={FechaRef} id="Fecha" name="Fecha" className="w-full" value={formik.values.Fecha} onChange={(e) => {
                                        formik.setFieldValue("Fecha", e.value)
                                        DelRef.current.focus()
                                    }} /> */}
                                            <InputText ref={FechaRef} id="Fecha" name="Fecha" className="w-full" value={formik.values.Fecha} onChange={formik.handleChange} onKeyDown={(e) => (e.key == "Enter") && DelRef.current.focus()} onBlur={validDateBook} />
                                            <label htmlFor="Fecha">Fecha</label>
                                        </span>
                                    </div>
                                    <div className="col-3">
                                        <span className="p-float-label">
                                            <InputText ref={DelRef} id="Del" name="Del" className="w-full" value={formik.values.Del} onChange={formik.handleChange} onKeyDown={(e) => (e.key == "Enter") && AlRef.current.focus()} />
                                            <label htmlFor="Del">Del No.</label>
                                        </span>
                                    </div>
                                    <div className="col-3">
                                        <span className="p-float-label">
                                            <InputText ref={AlRef} id="Al" name="Al" className="w-full" value={formik.values.Al} onChange={formik.handleChange} onKeyDown={(e) => (e.key == "Enter") && LocalesRef.current.focus()} />
                                            <label htmlFor="Al">Al No.</label>
                                        </span>
                                    </div>
                                    <div className="col-3">
                                        <span className="p-float-label">
                                            <InputText ref={LocalesRef} id="Locales" name="Locales" className="w-full" value={formik.values.Locales} onChange={formik.handleChange} onKeyDown={(e) => (e.key == "Enter") && FechaRef.current.focus()} />
                                            <label htmlFor="Locales">Locales</label>
                                        </span>
                                    </div>
                                </div>
                                <div className="grid">
                                    <div className="col-3"></div>
                                    <div className="col-3">
                                        <div className="grid">
                                            <div className="col-6">
                                                Calcular 1%
                                            </div>
                                            <div className="col-6">

                                                <InputSwitch checked={SwitchUno} onChange={(e) => setSwitchUno(e.value)} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="grid my-2">
                                    <div className="col-12 text-right">
                                        <Button severity="primary" type="submit">Guardar</Button>
                                    </div>
                                </div>
                            </BlockUI>
                        </form>
                    </div>
                </div>
            </div>

            <div className="grid">
                <div className="col-12">
                    <div className="card">
                        <h5>Libro de Ventas a Consumidor Final</h5>
                        <p>Ingresa tus documentos en el formulario</p>
                        <BlockUI blocked={blockLibro}>


                            <DataTable value={libroDetalles}
                                footerColumnGroup={footerGroup}
                                header={header}
                                paginator
                                sortField="Fecha"
                                sortOrder={-1}
                                rows={10}
                                rowsPerPageOptions={[5, 10, 25, 50]}
                                paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
                                currentPageReportTemplate="{first} to {last} of {totalRecords}">
                                <Column header="Fecha" field="Fecha" sortable></Column>
                                <Column header="Del No." field="Del_Numero"></Column>
                                <Column header="Al No." field="Al_Numero"></Column>
                                <Column header="Locales" field="Locales"></Column>
                                <Column header="Retencion (1%)" field="Retencion"></Column>
                                <Column header="Compras Totales" field="Venta_Total"></Column>
                            </DataTable>

                        </BlockUI>
                    </div>
                </div>
            </div>
        </>
    )
}

function initialValues() {
    return {
        Fecha: "",
        Del: "",
        Al: "",
        Locales: "",
        Cliente: "",
        Libro: "",
    }
}

function validationSchema() {
    return {
        Fecha: Yup.string().required(true),
        Del: Yup.string().required(true),
        Al: Yup.string().required(true),
        Locales: Yup.string().required(true),
        Cliente: Yup.string().required(true),
        Libro: Yup.string().required(true),
    }
}
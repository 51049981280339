import { useState } from "react"
import { useAuth } from "./useAuth"
import { getCatalogoApi } from "../api/client/catalogo"
import {addPartidaConfiguracionApi} from "../api/client/partidaconfiguracion"

export function usePartidas() {

    const [error, setError] = useState(false)
    const [loadingPartidas, setLoadingPartidas] = useState(false)
    const [catalogo, setCatalogo] = useState(null)

    const { auth } = useAuth();

    const onGetExportCatalogo = async () => {
        try {
            setLoadingPartidas(true)
            const getCatalogo = await getCatalogoApi(auth.token)
            let listCatalogo = []
            for await (const cuenta of getCatalogo) {
                listCatalogo.push({
                    "Codigo": cuenta.Codigo,
                    "NombreCuenta": cuenta.NombreCuenta
                });
            }

            setCatalogo(listCatalogo)
            setLoadingPartidas(false)
        } catch (error) {
            setLoadingPartidas(false)
            setError(error)
        }
    }

    const onSaveConfiguracionPartidas = async (data) => {
        try {
            setLoadingPartidas(true)
            await addPartidaConfiguracionApi(data, auth.token)
            setLoadingPartidas(false)
        } catch (error) {
            setLoadingPartidas(false)
            setError(error)
        }
    }

    return {
        error,
        loadingPartidas,
        catalogo,
        onGetExportCatalogo,
        onSaveConfiguracionPartidas
    }
}
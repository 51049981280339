import React, { useEffect, useRef, useState } from "react";
import {NumericFormat} from "react-number-format"
import { useFormik } from "formik"
import * as Yup from "yup"
import { size } from "lodash"
import moment from "moment"
import { Calendar } from "primereact/calendar"
import { InputText } from "primereact/inputtext"
import { Dropdown } from "primereact/dropdown"
import { Button } from "primereact/button";
import { DataTable } from "primereact/datatable"
import { ColumnGroup } from "primereact/columngroup";
import { Column } from "primereact/column"
import { Row } from "primereact/row"
import { BlockUI } from "primereact/blockui"
import { useLibros } from "../../hooks"
import { id_columns_editor } from "../../utils/dictionary";

export function LibroVentasPage() {

    const [blockLibro, setBlockLibro] = useState(true);
    const [switchImportacion, setSwitchImportacion] = useState(false);
    const [switchExentas, setSwitchExentas] = useState(false);
    const [tipoProveedor, setTipoProveedor] = useState(0);
    const [mesSeleccionado, setMesSeleccionado] = useState(0)
    const [fechaInicio, setFechaInicio] = useState(0)
    const [fechaFinal, setFechaFinal] = useState(0)

    const FechaRef = useRef(null);
    const ComprobanteRef = useRef(null);
    const RegistroRef = useRef(null);
    const ProveedorRef = useRef(null);
    const GravadasRef = useRef(null);

    const { getProveedorByNrc, librosDrop, getClientesForDropdown, clientesDrop, getLibrosForDropDown, getLibroDetalleFormat, libroDetalles, addLibroDetalle, updateLibroDetalle, getDatosLibro, addProveedor, getResolucionCliente } = useLibros();

    const formik = useFormik({
        initialValues: initialValues(),
        validationSchema: Yup.object(validationSchema()),
        onSubmit: async (formValue) => {
            let data_save = {
                FilaGroup: size(libroDetalles) + 1,
                Libro: formValue.Libro,
            }

            for await (const value of Object.keys(formValue)) {
                const value_insert = formValue[value]
                let bandera = true;

                switch (value) {
                    case "Cliente":
                    case "Libro":
                        bandera = false;
                        break;
                    case "Comprobante":
                        data_save["Columna"] = 2
                        break;
                    case "Fecha":
                        data_save["Columna"] = 11
                        break;
                    case "Gravadas":
                        data_save["Columna"] = 7

                        /* let dato_exentas_code = (switchExentas) ? "Exentas" : "Gravadas";
                        let dato_importacion_code = (switchImportacion) ? "Importacion" : "Internas";
                        let code_colum = `${dato_exentas_code}${dato_importacion_code}`

                        switch (code_colum) {
                            case "ExentasImportacion":
                                data_save["Columna"] = 26
                                break;
                            case "ExentasInternas":
                                data_save["Columna"] = 25
                                break;
                            case "GravadasImportacion":
                                data_save["Columna"] = 15
                                break;
                            case "GravadasInternas":
                                data_save["Columna"] = 14
                                break;
                            default:
                                break;
                        } */

                        break;
                    case "Iva":
                        data_save["Columna"] = 8
                        break;
                    case "Proveedor":
                        data_save["Columna"] = 4
                        break;
                    case "Registro":
                        data_save["Columna"] = 3
                        break;

                    default:
                        break;
                }
                if (bandera) {
                    if (value === "Fecha") {

                        data_save["Valor"] = moment(value_insert).format("YYYY-MM-DD")
                    } else {

                        data_save["Valor"] = value_insert
                    }
                    addLibroDetalle(data_save)

                }
            }

            data_save["Columna"] = 10
            data_save["Valor"] = (parseFloat(formValue.Gravadas) + parseFloat(formValue.Iva)).toFixed(2)
            await addLibroDetalle(data_save)

            data_save["Columna"] = 1
            data_save["Valor"] = size(libroDetalles) + 1
            await addLibroDetalle(data_save)

            await getLibroDetalleFormat(formValue.Libro)
            clearFields();
        }
    })

    useEffect(() => {
        (async () => {
            await getClientesForDropdown()
        })()
    }, [])

    useEffect(() => {
        if (mesSeleccionado != 0) {
            let fecha_libro = new Date(mesSeleccionado[0].Fecha)

            let startDate = new Date(fecha_libro.getFullYear(), fecha_libro.getMonth() + 1, 1)
            let endDate = new Date(fecha_libro.getFullYear(), fecha_libro.getMonth() + 2, 0)

            setFechaInicio(startDate)
            setFechaFinal(endDate)
        }
    }, [mesSeleccionado])

    const onSaveProveedor = async () => {

        await addProveedor({
            NrcProveedor: formik.values.Registro,
            NombreProveedor: formik.values.Proveedor,
            TipoProveedor: 0,
        })
    }

    const getProveedor = async (e) => {
        if (e.key === "Enter") {
            const response_api = await getProveedorByNrc(e.target.value)

            if (size(response_api) > 0) {
                formik.setFieldValue("Proveedor", response_api[0].NombreProveedor)
                setTipoProveedor(response_api[0].TipoProveedor)
                GravadasRef.current.focus();
            } else {
                formik.setFieldValue("Proveedor", "")
                setTipoProveedor(0)
                ProveedorRef.current.focus();
            }
        }
    }

    const clearFields = async () => {

        formik.setFieldValue("Comprobante", "")
        formik.setFieldValue("Registro", "")
        formik.setFieldValue("Proveedor", "")
        formik.setFieldValue("Gravadas", "")
        formik.setFieldValue("Iva", "")
    }

    const getLibroDependiente = async (e) => {
        formik.setFieldValue("Cliente", e.value)
        await getLibrosForDropDown(e.value, 2)
        setBlockLibro(true)
    }

    const getLibroDetalleDependiente = async (e) => {
        formik.setFieldValue("Libro", e.value)
        setMesSeleccionado(await getDatosLibro(e.value))
        await getLibroDetalleFormat(e.value)
        setBlockLibro(false)
    }

    const onRowEditComplete = async (e) => {
        let _rowLibro = [...libroDetalles]
        let { newData, index } = e

        _rowLibro[index] = newData

        for await (const llave of Object.keys(newData)) {
            let value = newData[llave]

            if (value != null) {

                switch (llave) {
                    case "Nombre_del_Proveedor":
                        const response_api = await getProveedorByNrc(newData.Numero_de_Registro)
                        if (size(response_api) > 0) {

                            newData[llave] = response_api[0].NombreProveedor
                            value = response_api[0].NombreProveedor
                        } else {
                            newData[llave] = "No existe el Proveedor"
                            value = "No existe el Proveedor"
                        }
                        break;
                    case "IVA":
                        if (newData.Internas != null) {
                            newData[llave] = (parseFloat(newData.Internas) * 0.13).toFixed(2)
                            value = (parseFloat(newData.Internas) * 0.13).toFixed(2)
                        } else if (newData.Importaciones != null) {
                            newData[llave] = (parseFloat(newData.Importaciones) * 0.13).toFixed(2)
                            value = (parseFloat(newData.Importaciones) * 0.13).toFixed(2)
                        } else {
                            newData[llave] = 0
                            value = 0
                        }
                        break;

                    case "Compras_totales":

                        if (newData.Internas != null) {
                            newData[llave] = (parseFloat(newData.Internas) * 1.13).toFixed(2)
                            value = (parseFloat(newData.Internas) * 1.13).toFixed(2)
                        } else if (newData.Importaciones != null) {
                            newData[llave] = (parseFloat(newData.Importaciones) * 1.13).toFixed(2)
                            value = (parseFloat(newData.Importaciones) * 1.13).toFixed(2)
                        } else {
                            if (newData.ExentasImportacion) {

                                newData[llave] = newData.ExentasImportacion
                                value = newData.ExentasImportacion
                            } else {
                                newData[llave] = newData.ExentasInternas
                                value = newData.ExentasInternas
                            }
                        }
                        break;
                    case "Retencion":
                        let response_grande = await getProveedorByNrc(newData.Numero_de_Registro)
                        if (size(response_grande) > 0) {

                            if (response_grande[0].TipoProveedor == 2 && newData.Internas != null) {

                                newData[llave] = (parseFloat(newData.Internas) * 0.01).toFixed(2)
                                value = (parseFloat(newData.Internas) * 0.01).toFixed(2)

                            }

                        }
                        break;
                    default:
                        break;
                }

                await updateLibroDetalle({
                    Valor: value
                }, newData.Libro_id, newData.FilaGroup, id_columns_editor[llave])

            }

        }

        //setLibrosDetalles(_rowLibro)
        await getLibroDetalleFormat(newData.Libro_id)
    }

    const textEditor = (options) => {
        let value_input = (options.value == null) ? "" : options.value
        return <InputText type="text" value={value_input} onChange={(e) => options.editorCallback(e.target.value)} />
    }

    const calendarEditor = (options) => {
        const data_calendar = new Date(options.value)
        return <Calendar value={data_calendar} dateFormat="yy-mm-dd" onChange={(e) => options.editorCallback(e.value)} />
    }

    const Ventas_GravadasTotal = () => {
        let total = 0

        if (size(libroDetalles) > 0) {

            for (const value of libroDetalles) {
                if (value.Ventas_Gravadas != null) {

                    total += parseFloat(value.Ventas_Gravadas)
                }
            }
        }
        return (
            <NumericFormat prefix="$" thousandSeparator displayType="text" decimalSeparator="." value={total.toFixed(2)} />
        )
    }
    const Debito_FiscalTotal = () => {
        let total = 0

        if (size(libroDetalles) > 0) {

            for (const value of libroDetalles) {
                if (value.Debito_Fiscal != null) {

                    total += parseFloat(value.Debito_Fiscal)
                }
            }
        }

        return (
            <NumericFormat prefix="$" thousandSeparator displayType="text" decimalSeparator="." value={total.toFixed(2)} />
        )
    }
    const Venta_TotalTotal = () => {
        let total = 0

        if (size(libroDetalles) > 0) {

            for (const value of libroDetalles) {
                if (value.Venta_Total != null) {

                    total += parseFloat(value.Venta_Total)
                }
            }
        }

        return (
            <NumericFormat prefix="$" thousandSeparator displayType="text" decimalSeparator="." value={total.toFixed(2)} />
        )
    }

    const exportExcel = async () => {

        let config_format_ap = []
        
        for (const libro of libroDetalles){
            
            let ap_data = {
                Correlativo: libro.Correlativo,
                Fecha: libro.Fecha,
                Numero_Correlativo_por_Tipo_de_Documento: libro.Numero_Correlativo_por_Tipo_de_Documento,
                Numero_de_Registro: libro.Numero_de_Registro,
                Nombre_del_Cliente: libro.Nombre_del_Cliente,
                Ventas_Gravadas: libro.Ventas_Gravadas,
                Debito_Fiscal: libro.Debito_Fiscal,
                Venta_Total: libro.Venta_Total,
            }

            config_format_ap.push(ap_data)

        }
        
        import('xlsx').then((xlsx) => {
            const worksheet = xlsx.utils.json_to_sheet(config_format_ap);
            const workbook = { Sheets: { data: worksheet }, SheetNames: ['data'] };
            const excelBuffer = xlsx.write(workbook, {
                bookType: 'xlsx',
                type: 'array'
            });

            saveAsExcelFile(excelBuffer, 'LibroCompras');
        });
    };

    const saveAsExcelFile = (buffer, fileName) => {
        import('file-saver').then((module) => {
            if (module && module.default) {
                let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
                let EXCEL_EXTENSION = '.xlsx';
                const data = new Blob([buffer], {
                    type: EXCEL_TYPE
                });

                module.default.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
            }
        });
    };

    const exportCSV = async (selectionOnly) => {

        const response_resolucion = await getResolucionCliente(formik.values.Cliente);

        let csvContent = "data:text/csv;charset=utf-8,";

        for await (const data of libroDetalles){
            if (data.Numero_de_Registro != "-"){

                csvContent += `${moment(data.Fecha).format("DD/MM/YYYY")};1;03;${response_resolucion.Resolucion};${response_resolucion.Serie};${data.Numero_Correlativo_por_Tipo_de_Documento};${data.Numero_Correlativo_por_Tipo_de_Documento};${data.Numero_de_Registro};${data.Nombre_del_Cliente};0.00;0.00;${(data.Ventas_Gravadas == null) ? '0.00' : data.Ventas_Gravadas};${(data.Debito_Fiscal == null) ? '0.00' : data.Debito_Fiscal};0.00;0.00;${(data.Venta_Total == null) ? '0.00' : data.Venta_Total};;1\n`;

            }
        }

        const encodedUri = encodeURI(csvContent);
        const link = document.createElement("a");
        link.setAttribute("href", encodedUri);
        link.setAttribute("download", `Contribuyentes-${response_resolucion.NombreCliente}.csv`);
        document.body.appendChild(link); // Required for Firefox
        link.click();
        
    };

    const header = (
        <div className="flex align-items-center justify-content-end gap-2">
            <Button type="button" icon="pi pi-file-excel" severity="success" rounded onClick={exportExcel} data-pr-tooltip="XLS" />
            <Button type="button" icon="pi pi-file" rounded onClick={() => exportCSV(false)} data-pr-tooltip="CSV" />
        </div>
    );

    const footerGroup = (
        <ColumnGroup>
            <Row>
                <Column footer="Totals:" colSpan={5} footerStyle={{ textAlign: 'right' }} />
                <Column footer={Ventas_GravadasTotal} />
                <Column footer={Debito_FiscalTotal} />
                <Column footer={Venta_TotalTotal} colSpan={2} />
            </Row>
        </ColumnGroup>
    );

    const validDateBook = (e) => {
        let value_date = moment(e.target.value);

        if (value_date >= fechaInicio && value_date <= fechaFinal) {
            console.log("pass")
        } else {
            formik.setFieldValue("Fecha", "")
            FechaRef.current.focus()
        }
    }

    return (
        <>
            <div className="grid">
                <div className="col-12">
                    <div className="card">
                        <h5>Libro de Ventas a Contribuyentes</h5>
                        <p>Ingresa tus documentos en el formulario</p>

                        <form onSubmit={formik.handleSubmit}>

                            <div className="grid my-4">
                                <div className="col-6">
                                    <span className="p-float-label">
                                        <Dropdown id="Cliente" name="Cliente" className="w-full" filter options={clientesDrop} optionLabel="name" value={formik.values.Cliente} onChange={(e) => getLibroDependiente(e)} />
                                        <label htmlFor="Cliente">Cliente</label>
                                    </span>
                                </div>
                                <div className="col-6">
                                    <span className="p-float-label">
                                        <Dropdown id="Libro" name="Libro" className="w-full" filter options={librosDrop} optionLabel="name" value={formik.values.Libro} onChange={(e) => getLibroDetalleDependiente(e)} />
                                        <label htmlFor="Libro">Libro</label>
                                    </span>
                                </div>
                            </div>

                            <BlockUI blocked={blockLibro}>
                                <div className="grid my-3">
                                    <div className="col-2">
                                        <span className="p-float-label">
                                            {/* <Calendar ref={FechaRef} id="Fecha" name="Fecha" className="w-full" value={formik.values.Fecha} onChange={(e) => {
                                        formik.setFieldValue("Fecha", e.value)
                                        ComprobanteRef.current.focus()
                                    }} /> */}
                                            <InputText ref={FechaRef} id="Fecha" name="Fecha" className="w-full" value={formik.values.Fecha} onChange={formik.handleChange} onKeyDown={(e) => (e.key == "Enter") && ComprobanteRef.current.focus()} onBlur={validDateBook} />
                                            <label htmlFor="Fecha">Fecha de Emision</label>
                                        </span>
                                    </div>
                                    <div className="col-2">
                                        <span className="p-float-label">
                                            <InputText ref={ComprobanteRef} id="Comprobante" name="Comprobante" className="w-full" value={formik.values.Comprobante} onChange={formik.handleChange} onKeyDown={(e) => (e.key == "Enter") && RegistroRef.current.focus()} />
                                            <label htmlFor="Comprobante">No. Correlativo por Tipo de Documento</label>
                                        </span>
                                    </div>
                                    <div className="col-2">
                                        <span className="p-float-label">
                                            <InputText ref={RegistroRef} id="Registro" name="Registro" className="w-full" value={formik.values.Registro} onChange={formik.handleChange} onKeyDown={getProveedor} />
                                            <label htmlFor="Registro">No. Registro</label>
                                        </span>
                                    </div>
                                    <div className="col-3">
                                        <span className="p-float-label">
                                            <InputText ref={ProveedorRef} id="Proveedor" name="Proveedor" className="w-full" value={formik.values.Proveedor} onChange={formik.handleChange} onKeyDown={(e) => (e.key == "Enter") && GravadasRef.current.focus()} onBlur={onSaveProveedor} />
                                            <label htmlFor="Proveedor">Nombre de Cliente</label>
                                        </span>
                                    </div>
                                    <div className="col-2">
                                        <span className="p-float-label">
                                            <InputText ref={GravadasRef} id="Gravadas" name="Gravadas" className="w-full" value={formik.values.Gravadas} onChange={formik.handleChange} onKeyDown={(e) => (e.key == "Enter") && FechaRef.current.focus()} onKeyUp={(e) => formik.setFieldValue("Iva", (e.target.value * 0.13))} />
                                            <label htmlFor="Gravadas">Ventas Gravadas</label>
                                        </span>
                                    </div>
                                    <div className="col-1">
                                        <span className="p-float-label">
                                            <InputText id="Iva" name="Iva" className="w-full" value={formik.values.Iva} onChange={formik.handleChange} />
                                            <label htmlFor="Iva">Debito Fiscal</label>
                                        </span>
                                    </div>
                                </div>
                                <div className="grid my-2">
                                    <div className="col-12 text-right">
                                        <Button severity="primary" type="submit">Guardar</Button>
                                    </div>
                                </div>
                            </BlockUI>
                        </form>
                    </div>
                </div>
            </div>

            <div className="grid">
                <div className="col-12">
                    <div className="card">
                        <h5>Libro de Ventas a Contribuyentes</h5>
                        <p>Ingresa tus documentos en el formulario</p>

                        <BlockUI blocked={blockLibro}>



                            <DataTable value={libroDetalles}
                                footerColumnGroup={footerGroup}
                                header={header}
                                paginator
                                sortField="Correlativo"
                                sortOrder={-1}
                                rows={10}
                                rowsPerPageOptions={[5, 10, 25, 50]}
                                paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
                                currentPageReportTemplate="{first} to {last} of {totalRecords}" editMode="row" dataKey="id" onRowEditComplete={onRowEditComplete}>
                                <Column header="Correlativo" field="Correlativo" sortable ></Column>
                                <Column editor={(options) => calendarEditor(options)} header="Fecha de Emision" sortable field="Fecha"></Column>
                                <Column editor={(options) => textEditor(options)} header="No. Correlativo por Tipo de Documento" field="Numero_Correlativo_por_Tipo_de_Documento"></Column>
                                <Column editor={(options) => textEditor(options)} header="No. de Registro" field="Numero_de_Registro"></Column>
                                <Column header="Nombre del Cliente" field="Nombre_del_Cliente"></Column>
                                <Column editor={(options) => textEditor(options)} header="Ventas Gravadas" field="Ventas_Gravadas"></Column>
                                <Column header="Debito Fiscal" field="Debito_Fiscal"></Column>
                                <Column header="Ventas Totales" field="Venta_Total"></Column>
                                <Column rowEditor={true} headerStyle={{ width: '10%', minWidth: '8rem' }} bodyStyle={{ textAlign: 'center' }}></Column>
                            </DataTable>

                        </BlockUI>
                    </div>
                </div>
            </div>
        </>
    )
}


function initialValues() {
    return {
        Fecha: "",
        Comprobante: "",
        Registro: "",
        Proveedor: "",
        Gravadas: "",
        Iva: "",
        Cliente: "",
        Libro: "",
    }
}

function validationSchema() {
    return {
        Fecha: Yup.string().required(true),
        Comprobante: Yup.string().required(true),
        Registro: Yup.string().required(true),
        Proveedor: Yup.string().required(true),
        Gravadas: Yup.string().required(true),
        Iva: Yup.string().required(true),
        Cliente: Yup.string().required(true),
        Libro: Yup.string().required(true),
    }
}
import React, { useState } from "react"
import { getMeApi } from "../api/users"
import {useAuth} from "./useAuth"

export function useUser(){
    const [loadingUser, setLoadingUser] = useState(false)
    const [error, setError] = useState(false)

    const {auth} = useAuth()

    const getMe = async (token) => {
        try {
            const response = await getMeApi(token)
            return response
        } catch (error) {
            throw error
        }
    }
 
    return {
        loadingUser,
        error,
        getMe
    }
}
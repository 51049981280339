import React, { useRef, useState, useEffect } from "react"
import { FileUpload } from "primereact/fileupload"
import { Toast } from "primereact/toast"
import { ProgressBar } from "primereact/progressbar"
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown"
import { Image } from "primereact/image"
import { BlockUI } from "primereact/blockui";
import { map, size } from "lodash"
import axios from 'axios';
import * as XLSX from 'xlsx';
import { useLibros } from "../../hooks";
import { columns_libros } from "../../utils/dictionary";
import excel_icon from "../../assets/images/sobresalir.png";

export function LoadLibroPage() {
    const [formatData, setFormatData] = useState(0)
    const [saveData, setFormatSaveData] = useState(0)
    const [dropCliente, setDropCliente] = useState(null);
    const [dropLibro, setDropLibro] = useState(null);
    const [blockImport, setBlockImport] = useState(true);

    const toast = useRef(null);

    const { getProveedorByNrc, librosDrop, getClientesForDropdown, clientesDrop, getLibrosForDropDown, addLibroDetalle } = useLibros();

    useEffect(() => {
        (async () => {
            await getClientesForDropdown()
        })()
    }, [])

    const getLibroDependiente = async (e) => {
        setDropCliente(e.value)
        await getLibrosForDropDown(e.value, "")
        setBlockImport(true)
    }

    const onUpload = async (e) => {
        toast.current.show({
            severity: "success",
            summary: "Correcto",
            detail: "Archivo cargado correctamente"
        })

        try {
            const url = e.files[0].objectURL; // Replace with your Excel file URL
            const response = await axios.get(url, { responseType: 'arraybuffer' });
            const data = new Uint8Array(response.data);
            const workbook = XLSX.read(data, { type: 'array' });
            const sheetName = workbook.SheetNames[0];
            const sheet = workbook.Sheets[sheetName];
            const jsonData = XLSX.utils.sheet_to_json(sheet, { header: 1 });
            let contador = 0
            let parse_data = []
            for (const row of jsonData) {

                if (contador > 0) {

                    let parse_data_detail = {}

                    map(jsonData[0], (value, index) => {
                        let del_space = value.replaceAll(" ", "_")
                        let del_point = del_space.replaceAll(".", "")
                        if (del_point == "fecha") {

                            const { getJsDateFromExcel } = require("excel-date-to-js")

                            parse_data_detail[del_point] = getJsDateFromExcel(row[index])

                        } else {
                            parse_data_detail[del_point] = row[index]

                        }
                    })

                    parse_data.push(parse_data_detail)

                }

                contador += 1
                const format_data_form = ((contador / size(jsonData)) * 100).toFixed(2)
                setFormatData(format_data_form)
            }

            toast.current.show({
                severity: "success",
                summary: "Correcto",
                detail: "Libro Formateado Correctamente"
            })

            let row_current = 1

            for await (const row of parse_data) {

                for await (const column of Object.keys(row)) {
                    let value_data = (row[column] != undefined) ? row[column] : "";
                    let id_column = columns_libros[column];
                    if (id_column != undefined) {

                        await addLibroDetalle({
                            Valor: value_data,
                            FilaGroup: row_current,
                            Libro: dropLibro,
                            Columna: id_column
                        })

                    }
                }

                const save_data_form = ((row_current / size(parse_data)) * 100).toFixed(2)
                setFormatSaveData(save_data_form)
                row_current += 1

            }

            toast.current.show({
                severity: "success",
                summary: "Correcto",
                detail: "Libro Importado Correctamente"
            })

        } catch (error) {
            toast.current.show({
                severity: "error",
                summary: "Error",
                detail: `Se encontro un error en el libro ${error}`
            })
        }

        try {

        } catch (error) {
            toast.current.show({
                severity: "error",
                summary: "Error",
                detail: `Se produjo un error al cargar el libro ${error}`
            })
        }

    }
    return (
        <div className="grid">
            <div className="col-12">
                <div className="card">

                    <Toast ref={toast} />

                    <div className="grid">
                        <div className="col-12">
                            <h3>Libros de IVA</h3>
                            <p>Selecciona el cliente al cual quieres cargar el libro</p>

                            <div className="grid my-5">
                                <div className="col-6">
                                    <span className="p-float-label">
                                        <Dropdown id="Cliente" name="Cliente" className="w-full" options={clientesDrop} optionLabel="name" value={dropCliente} onChange={(e) => getLibroDependiente(e)} />
                                        <label htmlFor="Cliente">Cliente</label>
                                    </span>
                                </div>
                                <div className="col-6">
                                    <span className="p-float-label">
                                        <Dropdown id="Libro" name="Libro" className="w-full" options={librosDrop} optionLabel="name" value={dropLibro} onChange={(e) => {
                                            setDropLibro(e.value)
                                            setBlockImport(false)
                                        }} />
                                        <label htmlFor="Libro">Libro</label>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>

                    <BlockUI blocked={blockImport}>
                        <div className="grid">
                            <div className="col-12">

                                <h3>Importar Libro de IVA</h3>
                                <p>En esta seccion puedes importar todos los libros de IVA (Compras, Ventas Contribuyentes y Ventas Consumidor Final)</p>

                                <FileUpload mode="basic" name="Libro" accept="*" url="#" maxFileSize={1000000000} customUpload uploadHandler={onUpload} />

                            </div>
                        </div>

                        <div className="grid my-5">
                            <div className="col-6">
                                <h3>Progreso de Formateo de Informacion</h3>
                                <ProgressBar value={formatData}></ProgressBar>
                            </div>
                            <div className="col-6">
                                <h3>Progreso de Guardado de Libro</h3>
                                <ProgressBar value={saveData}></ProgressBar>
                            </div>
                        </div>
                    </BlockUI>

                    <div className="grid my-5">
                        <div className="col-4 text-center">
                            <Button className="bg-bluegray-600 hover:bg-bluegray-400 border-bluegray-700">
                                <Image src={excel_icon} alt="icon_excel" width="100" />
                                <h4>Plantilla Libro de Compras</h4>
                            </Button>
                        </div>
                        <div className="col-4 text-center">
                            <Button className="bg-bluegray-600 hover:bg-bluegray-400 border-bluegray-700">
                                <Image src={excel_icon} alt="icon_excel" width="100" />
                                <h4>Plantilla Libro de Ventas a Contribuyentes</h4>
                            </Button>
                        </div>
                        <div className="col-4 text-center">
                            <Button className="bg-bluegray-600 hover:bg-bluegray-400 border-bluegray-700">
                                <Image src={excel_icon} alt="icon_excel" width="100" />
                                <h4>Plantilla Libro de Ventas a Consumidor Final</h4>
                            </Button>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    )
}
import { BASE_API } from "../../utils/constants";

export async function getLibroDetalleApi (token, Libro="", Columna="", FilaGroup="") {
    try {
        
        const libroFilter=`Libro=${Libro}`;
        const columnaFilter=`Columna=${Columna}`;
        const filaGroupFilter=`FilaGroup=${FilaGroup}`;

        const url = `${BASE_API}v1/LibroDetalle/?${libroFilter}&${columnaFilter}&${filaGroupFilter}`
        const params = {
            headers: {
                Authorization: `Bearer ${token}`
            }
        }

        const response = await fetch(url, params)
        const result = await response.json()

        return result

    } catch (error) {
        throw error;
    }
}

export async function addLibroDetalleApi (data, token) {
    try {
        const url = `${BASE_API}v1/LibroDetalle/`
        const params = {
            method: "POST",
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': "application/json"
            },
            body: JSON.stringify(data)
        }

        const response = await fetch(url, params)
        const result = await response.json();

        return result
    } catch (error) {
        throw error
    }
}

export async function updateLibroDetalleApi (id, data, token) {
    try {
        const url = `${BASE_API}v1/LibroDetalle/${id}/`
        const params = {
            method: "PATCH",
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': "application/json",
            },
            body: JSON.stringify(data)
        }

        const response = await fetch(url, params)
        const result = response.json()

        return result;
    } catch (error) {
        
    }
}

export async function deleteLibroDetalleApi (id, token) {
    try {
        const url = `${BASE_API}v1/LibroDetalle/${id}/`
        const params = {
            method: "DELETE",
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': "application/json",
            }
        }

        const response = await fetch(url, params)
        const result = response.json()

        return result;
    } catch (error) {
        
    }
}
import { BASE_API } from "../../utils/constants";

export async function getClienteApi(token, idCliente=""){
    try {

        let url = ""
        if (idCliente != ""){
            url = `${BASE_API}v1/Cliente/${idCliente}/`
        }else{
            url = `${BASE_API}v1/Cliente/`
        }

        const params = {
            headers: {
                Authorization: `Bearer ${token}`
            }
        }

        const response = await fetch(url, params);
        const result = await response.json()

        return result;
    } catch (error) {
        throw error
    }
}

export async function addClienteApi(data, token){
    try {
        const url = `${BASE_API}v1/Cliente/`;
        const params = {
            method: "POST",
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': "application/json"
            },
            body: JSON.stringify(data)
        };

        const response = await fetch(url, params);
        const result = await response.json();

        return result;
    } catch (error) {
        throw error
    }
}

export async function updateClienteApi(id, data, token){
    try {
        const url = `${BASE_API}v1/Cliente/${id}/`;
        const params = {
            methos: "PATCH",
            headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json"
            },
            body: JSON.stringify(data)
        }

        const response = await fetch(url, params);
        const result = await response.json();

        return result;
    } catch (error) {
        throw error;
    }
}
import { BASE_API } from "../../utils/constants";

export async function getLibroApi(token, Cliente="", TipoLibro="", FechaInicio ="", FechaFinal="", id=""){
    try {

        const clienteFilter = `Cliente=${Cliente}`
        const tipoLibroFilter = `TipoLibro=${TipoLibro}`
        const fechaInicioFilterGte = `Fecha__gte=${FechaInicio}`
        const fechaFinalFilterLte = `Fecha__lte=${FechaFinal}`
        const idFilter = `id=${id}`

        const url = `${BASE_API}v1/Libro/?${clienteFilter}&${tipoLibroFilter}&${fechaInicioFilterGte}&${fechaFinalFilterLte}&${idFilter}`
        const params = {
            headers: {
                Authorization: `Bearer ${token}`
            }
        }

        const response = await fetch(url, params);
        const result = await response.json()

        return result;
    } catch (error) {
        throw error
    }
}

export async function getLibroFormatApi(token, id=""){
    try {

        const idFilter = `id_libro=${id}`

        const url = `${BASE_API}v1/GetLibroFormat/?${idFilter}`
        const params = {
            headers: {
                Authorization: `Bearer ${token}`
            }
        }

        const response = await fetch(url, params);
        const result = await response.json()

        return result;
    } catch (error) {
        throw error
    }
}

export async function addLibroApi(data, token){
    try {
        const url = `${BASE_API}v1/Libro/`;
        const params = {
            method: "POST",
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': "application/json"
            },
            body: JSON.stringify(data)
        };

        const response = await fetch(url, params);
        const result = await response.json();

        return result;
    } catch (error) {
        throw error
    }
}

export async function updateLibroApi(id, data, token){
    try {
        const url = `${BASE_API}v1/Libro/${id}/`;
        const params = {
            methos: "PATCH",
            headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json"
            },
            body: JSON.stringify(data)
        }

        const response = await fetch(url, params);
        const result = await response.json();

        return result;
    } catch (error) {
        throw error;
    }
}
import { BASE_API } from "../../utils/constants";

export async function getProveedorApi(token, Nrc=""){
    try {

        const nrcFilter = `NrcProveedor=${Nrc}`

        const url = `${BASE_API}v1/Proveedor/?${nrcFilter}`
        const params = {
            headers: {
                Authorization: `Bearer ${token}`
            }
        }

        const response = await fetch(url, params);
        const result = await response.json()

        return result;
    } catch (error) {
        throw error
    }
}

export async function addProveedorApi(data, token){
    try {
        const url = `${BASE_API}v1/Proveedor/`;
        const params = {
            method: "POST",
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': "application/json"
            },
            body: JSON.stringify(data)
        };

        const response = await fetch(url, params);
        const result = await response.json();

        return result;
    } catch (error) {
        throw error
    }
}

export async function updateProveedorApi(id, data, token){
    try {
        const url = `${BASE_API}v1/Proveedor/${id}/`;
        const params = {
            methos: "PATCH",
            headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json"
            },
            body: JSON.stringify(data)
        }

        const response = await fetch(url, params);
        const result = await response.json();

        return result;
    } catch (error) {
        throw error;
    }
}
import { BASE_API } from "../../utils/constants";

export async function getPartidaConfiguracionApi (token) {
    try {
        
        const url = `${BASE_API}v1/PartidaConfiguracion/`
        const params = {
            headers: {
                Authorization: `Bearer ${token}`
            }
        }

        const response = await fetch(url, params)
        const result = await response.json()

        return result

    } catch (error) {
        throw error;
    }
}

export async function addPartidaConfiguracionApi (data, token) {
    try {
        const url = `${BASE_API}v1/PartidaConfiguracion/`
        const params = {
            method: "POST",
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': "application/json"
            },
            body: JSON.stringify(data)
        }

        const response = await fetch(url, params)
        const result = await response.json();

        return result
    } catch (error) {
        throw error
    }
}

export async function updatePartidaConfiguracionApi (id, data, token) {
    try {
        const url = `${BASE_API}v1/PartidaConfiguracion/${id}/`
        const params = {
            method: "PATCH",
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': "application/json",
            },
            body: JSON.stringify(data)
        }

        const response = await fetch(url, params)
        const result = response.json()

        return result;
    } catch (error) {
        
    }
}
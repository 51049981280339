import React, { useEffect, useRef, useState } from "react";
import { NumericFormat } from "react-number-format"
import { useFormik } from "formik"
import * as Yup from "yup"
import { size } from "lodash"
import moment from "moment"
import { Calendar } from "primereact/calendar"
import { InputText } from "primereact/inputtext"
import { Dropdown } from "primereact/dropdown"
import { Button } from "primereact/button";
import { InputSwitch } from "primereact/inputswitch"
import { DataTable } from "primereact/datatable"
import { ColumnGroup } from "primereact/columngroup";
import { Column } from "primereact/column"
import { Row } from "primereact/row"
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import { Toast } from 'primereact/toast';
import { BlockUI } from "primereact/blockui"
import { useLibros } from "../../hooks"
import { id_columns_editor } from "../../utils/dictionary";

export function LibroComprasPage() {

    const [blockLibro, setBlockLibro] = useState(true);
    const [switchImportacion, setSwitchImportacion] = useState(false);
    const [switchExentas, setSwitchExentas] = useState(false);
    const [tipoProveedor, setTipoProveedor] = useState(0);
    const [mesSeleccionado, setMesSeleccionado] = useState(0)
    const [fechaInicio, setFechaInicio] = useState(0)
    const [fechaFinal, setFechaFinal] = useState(0)

    const FechaRef = useRef(null);
    const toast = useRef(null);
    const dt = useRef(null);
    const ComprobanteRef = useRef(null);
    const RegistroRef = useRef(null);
    const ProveedorRef = useRef(null);
    const GravadasRef = useRef(null);

    const { getProveedorByNrc, librosDrop, getClientesForDropdown, clientesDrop, getLibrosForDropDown, getLibroDetalleFormat, libroDetalles, getDatosLibro, addLibroDetalle, updateLibroDetalle, addProveedor, getLibroDetalleDelete, deleteLibroDetalleDelete } = useLibros();

    const formik = useFormik({
        initialValues: initialValues(),
        validationSchema: Yup.object(validationSchema()),
        onSubmit: async (formValue) => {

            let data_save = {
                FilaGroup: size(libroDetalles) + 1,
                Libro: formValue.Libro,
            }

            for await (const value of Object.keys(formValue)) {
                const value_insert = formValue[value]
                let bandera = true;

                switch (value) {
                    case "Cliente":
                    case "Libro":
                        bandera = false;
                        break;
                    case "Comprobante":
                        data_save["Columna"] = 12
                        break;
                    case "Fecha":
                        data_save["Columna"] = 11
                        break;
                    case "Gravadas":
                        let dato_exentas_code = (switchExentas) ? "Exentas" : "Gravadas";
                        let dato_importacion_code = (switchImportacion) ? "Importacion" : "Internas";
                        let code_colum = `${dato_exentas_code}${dato_importacion_code}`

                        switch (code_colum) {
                            case "ExentasImportacion":
                                data_save["Columna"] = 26
                                break;
                            case "ExentasInternas":
                                data_save["Columna"] = 25
                                break;
                            case "GravadasImportacion":
                                data_save["Columna"] = 15
                                break;
                            case "GravadasInternas":
                                data_save["Columna"] = 14
                                break;
                            default:
                                break;
                        }

                        break;
                    case "Iva":
                        data_save["Columna"] = 16
                        break;
                    case "Proveedor":
                        data_save["Columna"] = 13
                        break;
                    case "Registro":
                        data_save["Columna"] = 3
                        break;

                    default:
                        break;
                }

                if (bandera) {
                    if (value === "Fecha") {

                        data_save["Valor"] = moment(value_insert).format("YYYY-MM-DD")
                    } else if (value === "Iva") {
                        if (!switchExentas) {

                            data_save["Valor"] = parseFloat(value_insert).toFixed(2)
                        } else {
                            data_save["Valor"] = 0
                        }
                    } else {

                        data_save["Valor"] = value_insert
                    }
                    await addLibroDetalle(data_save)

                }
            }

            let retencion_sum = 0

            if (tipoProveedor == 2 && parseFloat(formValue.Gravadas) > 99.99 && !switchImportacion) {
                data_save["Columna"] = 17
                data_save["Valor"] = (parseFloat(formValue.Gravadas) * 0.01).toFixed(2)
                retencion_sum = (parseFloat(formValue.Gravadas) * 0.01).toFixed(2)
                await addLibroDetalle(data_save)
            }

            data_save["Columna"] = 18
            if (!switchExentas) {

                data_save["Valor"] = (parseFloat(formValue.Gravadas) + parseFloat(formValue.Iva) + parseFloat(retencion_sum)).toFixed(2)
            } else {
                data_save["Valor"] = (parseFloat(formValue.Gravadas) + parseFloat(retencion_sum)).toFixed(2)

            }
            await addLibroDetalle(data_save)

            data_save["Columna"] = 1
            data_save["Valor"] = size(libroDetalles) + 1
            await addLibroDetalle(data_save)

            await getLibroDetalleFormat(formValue.Libro)
            clearFields();
        }
    })

    useEffect(() => {
        (async () => {
            await getClientesForDropdown()
        })()
    }, [])

    useEffect(() => {
        if (mesSeleccionado != 0) {
            let fecha_libro = new Date(mesSeleccionado[0].Fecha)

            let startDate = new Date(fecha_libro.getFullYear(), fecha_libro.getMonth() + 1, 1)
            let endDate = new Date(fecha_libro.getFullYear(), fecha_libro.getMonth() + 2, 0)

            setFechaInicio(startDate)
            setFechaFinal(endDate)
        }
    }, [mesSeleccionado])

    const getProveedor = async (e) => {
        if (e.key === "Enter") {
            const response_api = await getProveedorByNrc(e.target.value)

            if (size(response_api) > 0) {
                formik.setFieldValue("Proveedor", response_api[0].NombreProveedor)
                setTipoProveedor(response_api[0].TipoProveedor)
                GravadasRef.current.focus();
            } else {
                formik.setFieldValue("Proveedor", "")
                setTipoProveedor(0)
                ProveedorRef.current.focus();
            }
        }
    }

    const clearFields = async () => {

        //formik.setFieldValue("Fecha", "")
        formik.setFieldValue("Comprobante", "")
        formik.setFieldValue("Registro", "")
        formik.setFieldValue("Proveedor", "")
        formik.setFieldValue("Gravadas", "")
        formik.setFieldValue("Iva", "")
    }

    const getLibroDependiente = async (e) => {
        formik.setFieldValue("Cliente", e.value)
        await getLibrosForDropDown(e.value, 1)
        setBlockLibro(true)
    }

    const getLibroDetalleDependiente = async (e) => {
        formik.setFieldValue("Libro", e.value)
        setMesSeleccionado(await getDatosLibro(e.value))
        await getLibroDetalleFormat(e.value)
        setBlockLibro(false)
    }

    const label_column_field = () => {
        let str_camp = "Compras"

        let dato_importacion = (switchImportacion) ? "Importacion" : "Internas"
        let dato_exentas = (switchExentas) ? "Exentas" : "Gravadas"

        return `${str_camp} ${dato_exentas} ${dato_importacion}`

    }

    const templateFecha = (rowData) => {
        const date_str = moment(rowData.Fecha).format("YYYY-MM-DD")
        return (date_str == "Invalid date") ? rowData.Fecha : date_str
    }

    const templateFixTotal = (rowData) => {
        return parseFloat(rowData.Compras_totales).toFixed(2)
    }
    const templateFixGravadas = (rowData) => {
        return (rowData.Internas) ? parseFloat(rowData.Internas).toFixed(2) : ""
    }
    const templateFixIva = (rowData) => {
        return parseFloat(rowData.IVA).toFixed(2)
    }
    const templateFixRetencion = (rowData) => {

        return (rowData.Retencion != null) ? parseFloat(rowData.Retencion).toFixed(2) : 0
    }

    const allowEdit = (rowData) => {
        return true
    }

    const onRowEditComplete = async (e) => {
        let _rowLibro = [...libroDetalles]
        let { newData, index } = e

        _rowLibro[index] = newData

        for await (const llave of Object.keys(newData)) {
            let value = newData[llave]

            if (value != null) {

                switch (llave) {
                    case "Nombre_del_Proveedor":
                        const response_api = await getProveedorByNrc(newData.Numero_de_Registro)
                        if (size(response_api) > 0) {

                            newData[llave] = response_api[0].NombreProveedor
                            value = response_api[0].NombreProveedor
                        } else {
                            newData[llave] = "No existe el Proveedor"
                            value = "No existe el Proveedor"
                        }
                        break;
                    case "IVA":
                        if (newData.Internas != null) {
                            newData[llave] = (parseFloat(newData.Internas) * 0.13).toFixed(2)
                            value = (parseFloat(newData.Internas) * 0.13).toFixed(2)
                        } else if (newData.Importaciones != null) {
                            newData[llave] = (parseFloat(newData.Importaciones) * 0.13).toFixed(2)
                            value = (parseFloat(newData.Importaciones) * 0.13).toFixed(2)
                        } else {
                            newData[llave] = 0
                            value = 0
                        }
                        break;

                    case "Compras_totales":

                        if (newData.Internas != null) {
                            newData[llave] = (parseFloat(newData.Internas) * 1.13).toFixed(2)
                            value = (parseFloat(newData.Internas) * 1.13).toFixed(2)
                        } else if (newData.Importaciones != null) {
                            newData[llave] = (parseFloat(newData.Importaciones) * 1.13).toFixed(2)
                            value = (parseFloat(newData.Importaciones) * 1.13).toFixed(2)
                        } else {
                            if (newData.ExentasImportacion) {

                                newData[llave] = newData.ExentasImportacion
                                value = newData.ExentasImportacion
                            } else {
                                newData[llave] = newData.ExentasInternas
                                value = newData.ExentasInternas
                            }
                        }
                        break;
                    case "Retencion":
                        let response_grande = await getProveedorByNrc(newData.Numero_de_Registro)
                        if (size(response_grande) > 0) {

                            if (response_grande[0].TipoProveedor == 2 && newData.Internas != null) {

                                newData[llave] = (parseFloat(newData.Internas) * 0.01).toFixed(2)
                                value = (parseFloat(newData.Internas) * 0.01).toFixed(2)

                            }

                        }
                        break;
                    default:
                        break;
                }

                await updateLibroDetalle({
                    Valor: value
                }, newData.Libro_id, newData.FilaGroup, id_columns_editor[llave])

            }

        }

        //setLibrosDetalles(_rowLibro)
        await getLibroDetalleFormat(newData.Libro_id)
    }

    const textEditor = (options) => {
        let value_input = (options.value == null) ? "" : options.value
        return <InputText type="text" value={value_input} onChange={(e) => options.editorCallback(e.target.value)} />
    }

    const calendarEditor = (options) => {
        const data_calendar = new Date(options.value)
        return <Calendar value={data_calendar} dateFormat="yy-mm-dd" onChange={(e) => options.editorCallback(e.value)} />
    }

    const validDateBook = (e) => {
        let value_date = moment(e.target.value);

        if (value_date >= fechaInicio && value_date <= fechaFinal) {
            console.log("pass")
        } else {
            formik.setFieldValue("Fecha", "")
            FechaRef.current.focus()
        }
    }






    const ExentasInternasTotal = () => {

        let total = 0

        if (size(libroDetalles) > 0) {

            for (const value of libroDetalles) {
                if (value.ExentasInternas != null) {

                    total += parseFloat(value.ExentasInternas)
                }
            }
        }

        return (
            <NumericFormat prefix="$" thousandSeparator displayType="text" decimalSeparator="." value={total.toFixed(2)} />
        )
    }

    const ExentasImportacionTotal = () => {

        let total = 0

        if (size(libroDetalles) > 0) {

            for (const value of libroDetalles) {
                if (value.ExentasImportacion != null) {

                    total += parseFloat(value.ExentasImportacion)
                }
            }
        }

        return (
            <NumericFormat prefix="$" thousandSeparator displayType="text" decimalSeparator="." value={total.toFixed(2)} />
        )
    }

    const GravadasInternasTotal = () => {

        let total = 0

        if (size(libroDetalles) > 0) {

            for (const value of libroDetalles) {
                if (value.Internas != null) {

                    total += parseFloat(value.Internas)
                }
            }
        }

        return (
            <NumericFormat prefix="$" thousandSeparator displayType="text" decimalSeparator="." value={total.toFixed(2)} />
        )
    }

    const GravadasImportacion = () => {

        let total = 0

        if (size(libroDetalles) > 0) {

            for (const value of libroDetalles) {
                if (value.Importaciones != null) {

                    total += parseFloat(value.Importaciones)
                }
            }
        }

        return (
            <NumericFormat prefix="$" thousandSeparator displayType="text" decimalSeparator="." value={total.toFixed(2)} />
        )
    }

    const IvaTotal = () => {
        let total = 0

        if (size(libroDetalles) > 0) {

            for (const value of libroDetalles) {
                if (value.IVA != null) {

                    total += parseFloat(value.IVA)
                }
            }
        }

        return (
            <NumericFormat prefix="$" thousandSeparator displayType="text" decimalSeparator="." value={total.toFixed(2)} />
        )
    }
    const RetencionTotal = () => {
        let total = 0

        if (size(libroDetalles) > 0) {

            for (const value of libroDetalles) {
                if (value.Retencion != null) {

                    total += parseFloat(value.Retencion)
                }
            }
        }

        return (
            <NumericFormat prefix="$" thousandSeparator displayType="text" decimalSeparator="." value={total.toFixed(2)} />
        )
    }
    const ComprasTotal = () => {
        let total = 0

        if (size(libroDetalles) > 0) {

            for (const value of libroDetalles) {
                if (value.Compras_totales != null) {

                    total += parseFloat(value.Compras_totales)
                }
            }
        }

        return (
            <NumericFormat prefix="$" thousandSeparator displayType="text" decimalSeparator="." value={total.toFixed(2)} />
        )
    }

    const exportExcel = async () => {

        let config_format_ap = []

        for (const libro of libroDetalles) {

            let ap_data = {
                Correlativo: libro.Correlativo,
                Fecha: libro.Fecha,
                Numero_de_Comprobante: libro.Numero_de_Comprobante,
                Numero_de_Registro: libro.Numero_de_Registro,
                Nombre_del_Proveedor: libro.Nombre_del_Proveedor,
                ExentasInternas: libro.ExentasInternas,
                ExentasImportacion: libro.ExentasImportacion,
                Internas: libro.Internas,
                Importaciones: libro.Importaciones,
                IVA: libro.IVA,
                Retencion: libro.Retencion,
                Compras_totales: libro.Compras_totales,
            }

            config_format_ap.push(ap_data)

        }

        import('xlsx').then((xlsx) => {
            const worksheet = xlsx.utils.json_to_sheet(config_format_ap);
            const workbook = { Sheets: { data: worksheet }, SheetNames: ['data'] };
            const excelBuffer = xlsx.write(workbook, {
                bookType: 'xlsx',
                type: 'array'
            });

            saveAsExcelFile(excelBuffer, 'LibroCompras');
        });
    };

    const saveAsExcelFile = (buffer, fileName) => {
        import('file-saver').then((module) => {
            if (module && module.default) {
                let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
                let EXCEL_EXTENSION = '.xlsx';
                const data = new Blob([buffer], {
                    type: EXCEL_TYPE
                });

                module.default.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
            }
        });
    };

    const onSaveProveedor = async () => {

        await addProveedor({
            NrcProveedor: formik.values.Registro,
            NombreProveedor: formik.values.Proveedor,
            TipoProveedor: 0,
        })
    }

    const exportCSV = async (selectionOnly) => {
        let csvContent = "data:text/csv;charset=utf-8,";

        for await (const data of libroDetalles) {
            if (data.Numero_de_Comprobante.includes("DTE")) {
                csvContent += `${moment(data.Fecha).format("DD/MM/YYYY")};4;03;${data.Numero_de_Comprobante.replaceAll("-", '')};${data.Numero_de_Registro};${data.Nombre_del_Proveedor.replace(/,/g, ',')};${(data.ExentasInternas == null) ? '0.00' : data.ExentasInternas};${(data.ExentasImportacion == null) ? '0.00' : data.ExentasImportacion};${(data.Importaciones == null) ? '0.00' : data.Importaciones};${(data.Internas == null) ? '0.00' : data.Internas};0.00;0.00;0.00;${(data.IVA == null) ? '0.00' : data.IVA};${(data.Compras_totales == null) ? '0.00' : data.Compras_totales};;1;2;2;5;3\n`;
            } else {
                csvContent += `${moment(data.Fecha).format("DD/MM/YYYY")};1;03;${data.Numero_de_Comprobante.replaceAll("-", '')};${data.Numero_de_Registro};${data.Nombre_del_Proveedor.replace(/,/g, ',')};${(data.ExentasInternas == null) ? '0.00' : data.ExentasInternas};${(data.ExentasImportacion == null) ? '0.00' : data.ExentasImportacion};${(data.Importaciones == null) ? '0.00' : data.Importaciones};${(data.Internas == null) ? '0.00' : data.Internas};0.00;0.00;0.00;${(data.IVA == null) ? '0.00' : data.IVA};${(data.Compras_totales == null) ? '0.00' : data.Compras_totales};;1;2;2;5;3\n`;
            }
        }

        const encodedUri = encodeURI(csvContent);
        const link = document.createElement("a");
        link.setAttribute("href", encodedUri);
        link.setAttribute("download", `Compras.csv`);
        document.body.appendChild(link); // Required for Firefox
        link.click();

    };

    const templateClaseDocumento = () => {
        return '1'
    }


    const header = (
        <div className="flex align-items-center justify-content-end gap-2">
            <Button type="button" icon="pi pi-file-excel" severity="success" rounded onClick={exportExcel} data-pr-tooltip="XLS" />
            <Button type="button" icon="pi pi-file" rounded onClick={() => exportCSV(false)} data-pr-tooltip="CSV" />
        </div>
    );

    const onDeleteDetalle = async (data, row) => {

        confirmDialog({
            message: '¿Esta seguro que desea eliminar este registro?',
            header: 'Eliminar',
            icon: 'pi pi-info-circle',
            position: 'right',
            accept: async () => {

                const getDataELiminar = await getLibroDetalleDelete(data.Libro_id, data.FilaGroup);

                for await (const row of getDataELiminar) {
                    await deleteLibroDetalleDelete(row.id)
                }

                toast.current.show({ severity: 'info', summary: 'Eliminado', detail: 'Registro Eliminado Correctamente', life: 3000 });

                await getLibroDetalleFormat(data.Libro_id)

            },
            reject: () => {
                toast.current.show({ severity: 'warn', summary: 'Cancelado', detail: 'Registro NO Eliminado', life: 3000 });
            }
        });

    }

    const onUpdateRetencion = async (data) => {
        confirmDialog({
            message: '¿Esta seguro que desea dejar a cero (0) la retención?',
            header: 'Calcular',
            icon: 'pi pi-info-circle',
            position: 'right',
            accept: async () => {

                await updateLibroDetalle({
                    Valor: 0
                }, data.Libro_id, data.FilaGroup, 17)

                toast.current.show({ severity: 'info', summary: 'Eliminado', detail: 'Calculo aplicado', life: 3000 });

                await getLibroDetalleFormat(data.Libro_id)

            },
            reject: () => {
                toast.current.show({ severity: 'warn', summary: 'Cancelado', detail: 'Retencion NO cambio', life: 3000 });
            }
        });
    }

    const actionTemplate = (rowdata) => {

        return (
            <div className="flex flex-wrap justify-content-center gap-3 mb-4">
                <Button severity="danger" rounded icon="pi pi-times" onClick={(e) => onDeleteDetalle(rowdata, e)}></Button>
                <Button severity="info" rounded icon="pi pi-percentage" onClick={(e) => onUpdateRetencion(rowdata, e)}></Button>
            </div>
        )
    }


    const headerGroup = (
        <ColumnGroup>
            <Row>
                <Column></Column>
                <Column></Column>
                <Column></Column>
                <Column></Column>
                <Column></Column>
                <Column></Column>
                <Column header="Compras Exentas" headerStyle={{ textAlign: "center" }} colSpan={2}></Column>
                <Column header="Compras Gravadas" colSpan={2}></Column>
                <Column></Column>
                <Column></Column>
                <Column></Column>
                <Column></Column>
                <Column></Column>
            </Row>
            <Row>
                <Column header="Correlativo" field="Correlativo" sortable rowSpan={2} ></Column>
                <Column header="Fecha" field="Fecha" sortable rowSpan={2} ></Column>
                <Column header="Clase de Documento" field="Clase_Documento" rowSpan={2}></Column>
                <Column header="Numero de Comprobante" field="Numero_de_Comprobante" rowSpan={2} ></Column>
                <Column header="Numero de Registro" field="Numero_de_Registro" rowSpan={2} ></Column>
                <Column header="Nombre del Proveedor" field="Nombre_del_Proveedor" rowSpan={2} ></Column>
                <Column header="Internas" field="ExentasInternas"></Column>
                <Column header="Importacion" field="ExentasImportacion"></Column>
                <Column header="Internas" field="Internas"></Column>
                <Column header="Importacion" field="Importaciones"></Column>
                <Column header="IVA" field="IVA" rowSpan={2} ></Column>
                <Column header="Retencion" field="Retencion" rowSpan={2} ></Column>
                <Column header="Compras Totales" field="Compras_totales" rowSpan={2} ></Column>
                <Column rowSpan={2} colSpan={2} ></Column>
            </Row>
        </ColumnGroup>
    )

    const footerGroup = (
        <ColumnGroup>
            <Row>
                <Column footer="Totals:" colSpan={6} footerStyle={{ textAlign: 'right' }} />
                <Column footer={ExentasInternasTotal} />
                <Column footer={ExentasImportacionTotal} />
                <Column footer={GravadasInternasTotal} />
                <Column footer={GravadasImportacion} />
                <Column footer={IvaTotal} />
                <Column footer={RetencionTotal} />
                <Column footer={ComprasTotal} colSpan={2} />
            </Row>
        </ColumnGroup>
    );

    return (
        <>
            <Toast ref={toast} />
            <ConfirmDialog />
            <div className="grid">
                <div className="col-12">
                    <div className="card">
                        <h5>Libro de Compras</h5>
                        <p>Ingresa tus documentos en el formulario</p>

                        <form onSubmit={formik.handleSubmit}>

                            <div className="grid my-4">
                                <div className="col-6">
                                    <span className="p-float-label">
                                        <Dropdown id="Cliente" name="Cliente" className="w-full" filter options={clientesDrop} optionLabel="name" value={formik.values.Cliente} onChange={(e) => getLibroDependiente(e)} />
                                        <label htmlFor="Cliente">Cliente</label>
                                    </span>
                                </div>
                                <div className="col-6">
                                    <span className="p-float-label">
                                        <Dropdown id="Libro" name="Libro" className="w-full" filter options={librosDrop} optionLabel="name" value={formik.values.Libro} onChange={(e) => getLibroDetalleDependiente(e)} />
                                        <label htmlFor="Libro">Libro</label>
                                    </span>
                                </div>
                            </div>

                            <BlockUI blocked={blockLibro}>
                                <div className="grid my-3">
                                    <div className="col-2">
                                        <span className="p-float-label">
                                            {/* <Calendar ref={FechaRef} id="Fecha" name="Fecha" className="w-full" value={formik.values.Fecha} onChange={(e) => {
                                        formik.setFieldValue("Fecha", e.value)
                                        ComprobanteRef.current.focus()
                                    }} /> */}
                                            <InputText ref={FechaRef} id="Fecha" name="Fecha" className="w-full" value={formik.values.Fecha} onChange={formik.handleChange} onKeyDown={(e) => (e.key == "Enter") && ComprobanteRef.current.focus()} onBlur={validDateBook} />
                                            <label htmlFor="Fecha">Fecha</label>
                                        </span>
                                    </div>
                                    <div className="col-2">
                                        <span className="p-float-label">
                                            <InputText ref={ComprobanteRef} id="Comprobante" name="Comprobante" className="w-full" value={formik.values.Comprobante} onChange={formik.handleChange} onKeyDown={(e) => (e.key == "Enter") && RegistroRef.current.focus()} />
                                            <label htmlFor="Comprobante">No. Comprobante</label>
                                        </span>
                                    </div>
                                    <div className="col-2">
                                        <span className="p-float-label">
                                            <InputText ref={RegistroRef} id="Registro" name="Registro" className="w-full" value={formik.values.Registro} onChange={formik.handleChange} onKeyDown={getProveedor} />
                                            <label htmlFor="Registro">No. Registro</label>
                                        </span>
                                    </div>
                                    <div className="col-3">
                                        <span className="p-float-label">
                                            <InputText ref={ProveedorRef} id="Proveedor" name="Proveedor" className="w-full" value={formik.values.Proveedor} onChange={formik.handleChange} onKeyDown={(e) => (e.key == "Enter") && GravadasRef.current.focus()} onBlur={onSaveProveedor} />
                                            <label htmlFor="Proveedor">Nombre de Proveedor</label>
                                        </span>
                                    </div>
                                    <div className="col-2">
                                        <span className="p-float-label">
                                            <InputText ref={GravadasRef} id="Gravadas" name="Gravadas" className="w-full" value={formik.values.Gravadas} onChange={formik.handleChange} onKeyDown={(e) => (e.key == "Enter") && FechaRef.current.focus()} onKeyUp={(e) => {
                                                formik.setFieldValue("Iva", (e.target.value * 0.13).toFixed(2))
                                                if (tipoProveedor == 2 && parseFloat(e.target.value) > 99.99 && !switchImportacion) {

                                                    formik.setFieldValue("Retencion", (e.target.value * 0.01).toFixed(2))

                                                } else {
                                                    formik.setFieldValue("Retencion", 0)

                                                }
                                            }} />
                                            <label htmlFor="Gravadas">{label_column_field()}</label>
                                        </span>
                                    </div>
                                    <div className="col-1">
                                        <span className="p-float-label">
                                            <InputText id="Iva" name="Iva" className="w-full" value={formik.values.Iva} onChange={formik.handleChange} />
                                            <label htmlFor="Iva">IVA</label>
                                        </span>
                                    </div>
                                </div>
                                <div className="grid">
                                    <div className="col-2">

                                    </div>
                                    <div className="col-2">
                                        <div className="grid">
                                            <div className="col-6">
                                                Exentas
                                            </div>
                                            <div className="col-6">
                                                <InputSwitch checked={switchExentas} onChange={(e) => setSwitchExentas(e.value)} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-2">
                                        <div className="grid">
                                            <div className="col-6">
                                                Importacion
                                            </div>
                                            <div className="col-6">
                                                <InputSwitch checked={switchImportacion} onChange={(e) => setSwitchImportacion(e.value)} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-3">

                                    </div>
                                    <div className="col-2">

                                    </div>
                                    <div className="col-1">
                                        <span className="p-float-label">
                                            <InputText id="Retencion" name="Retencion" className="w-full" value={formik.values.Retencion} onChange={formik.handleChange} />
                                            <label htmlFor="Retencion">Retencion</label>
                                        </span>
                                    </div>
                                </div>
                                <div className="grid my-2">
                                    <div className="col-12 text-right">
                                        <Button severity="primary" type="submit">Guardar</Button>
                                    </div>
                                </div>
                            </BlockUI>
                        </form>

                    </div>
                </div>
            </div>

            <div className="grid">
                <div className="col-12">
                    <div className="card">
                        <h5>Libro de Compras</h5>
                        <p>Aqui puedes ver los documentos digitados</p>
                        <BlockUI blocked={blockLibro}>

                            <DataTable value={libroDetalles}
                                ref={dt}
                                headerColumnGroup={headerGroup}
                                footerColumnGroup={footerGroup}
                                stripedRows
                                size="small"
                                sortField="Correlativo"
                                sortOrder={-1}
                                header={header}
                                paginator
                                rows={10}
                                rowsPerPageOptions={[5, 10, 50, 100]}
                                paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
                                currentPageReportTemplate="{first} to {last} of {totalRecords}"
                                editMode="row" dataKey="id" onRowEditComplete={onRowEditComplete}>
                                <Column field="Correlativo" sortable></Column>
                                <Column editor={(options) => textEditor(options)} field="Fecha" sortable body={templateFecha}></Column>
                                <Column body={templateClaseDocumento} field="Clase_Documento" ></Column>
                                <Column editor={(options) => textEditor(options)} field="Numero_de_Comprobante"></Column>
                                <Column editor={(options) => textEditor(options)} field="Numero_de_Registro"></Column>
                                <Column field="Nombre_del_Proveedor"></Column>
                                <Column editor={(options) => textEditor(options)} field="ExentasInternas"></Column>
                                <Column editor={(options) => textEditor(options)} field="ExentasImportacion"></Column>
                                <Column editor={(options) => textEditor(options)} field="Internas" body={templateFixGravadas}></Column>
                                <Column editor={(options) => textEditor(options)} field="Importaciones"></Column>
                                <Column field="IVA" body={templateFixIva}></Column>
                                <Column field="Retencion" body={templateFixRetencion}></Column>
                                <Column field="Compras_totales" body={templateFixTotal}></Column>
                                <Column rowEditor={allowEdit} headerStyle={{ width: '10%', minWidth: '8rem' }} bodyStyle={{ textAlign: 'center' }}></Column>
                                <Column body={actionTemplate}></Column>
                            </DataTable>

                        </BlockUI>
                    </div>
                </div>
            </div>
        </>
    )
}

function initialValues() {
    return {
        Fecha: "",
        Comprobante: "",
        Registro: "",
        Proveedor: "",
        Gravadas: "",
        Iva: "",
        Cliente: "",
        Libro: "",
        Retencion: "",
    }
}

function validationSchema() {
    return {
        Fecha: Yup.string().required(true),
        Comprobante: Yup.string().required(true),
        Registro: Yup.string().required(true),
        Proveedor: Yup.string().required(true),
        Gravadas: Yup.string().required(true),
        Iva: Yup.string().required(true),
        Cliente: Yup.string().required(true),
        Libro: Yup.string().required(true),
        Retencion: Yup.string().required(true),
    }
}
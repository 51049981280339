import React from "react";
import { ProgressBar } from 'primereact/progressbar';
import {FiltroEmpleado} from "../../components/filtros"

export function GenerarPartidasPage () {

    const onResponse = async (formData) => {
        console.log(formData)
    }

    return (
        <div className="grid">
            <div className="col-12">

                <div className="grid">
                    <div className="col-12">
                        <div className="card">
                            <h4>Generar Partidas</h4>
                            <p>Selecciona el cliente y el libro para poder generar las partidas</p>
                            <FiltroEmpleado onResponse={onResponse} />
                        </div>
                    </div>
                </div>

                <div className="grid">
                    <div className="col-12">
                        <div className="card">
                            <h4>Resultado</h4>
                            <p>Aqui puedes ver el progreso de la generacion de partidas para el cliente seleccionado</p>
                            <ProgressBar value={50}></ProgressBar>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    )
}
import { BASE_API } from "../../utils/constants";

export async function getCatalogoApi(token){
    try {
        const url = `${BASE_API}v1/Catalogo/`
        const params = {
            headers: {
                Authorization: `Bearer ${token}`
            }
        }

        const response = await fetch(url, params);
        const result = await response.json()

        return result;
    } catch (error) {
        throw error
    }
}

export async function addCatalogoApi(data, token){
    try {
        const url = `${BASE_API}v1/Catalogo/`;
        const params = {
            method: "POST",
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': "application/json"
            },
            body: JSON.stringify(data)
        };

        const response = await fetch(url, params);
        const result = await response.json();

        return result;
    } catch (error) {
        throw error
    }
}

export async function updateCatalogoApi(id, data, token){
    try {
        const url = `${BASE_API}v1/Catalogo/${id}/`;
        const params = {
            methos: "PATCH",
            headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json"
            },
            body: JSON.stringify(data)
        }

        const response = await fetch(url, params);
        const result = await response.json();

        return result;
    } catch (error) {
        throw error;
    }
}
import React, { useEffect, useState } from "react";
import { Button } from "primereact/button";
import { Image } from "primereact/image";
import { ProgressBar } from 'primereact/progressbar';
import { FileUpload } from 'primereact/fileupload';
import {usePartidas} from "../../hooks"
import excel_icon from "../../assets/images/sobresalir.png";
import { BASE_API } from "../../utils/constants";

export function LoadPartidasPage() {

    const [porcentaje, setPorcentaje] = useState(0)

    const {catalogo, onGetExportCatalogo, onSaveConfiguracionPartidas} = usePartidas();

    useEffect(() => {
        (async () => {
            await onGetExportCatalogo();
        })()
    }, [])

    const onUpload = async (e) => {
        const jsonData = JSON.parse(e.xhr.response);
        const size_array = jsonData.length
        let contador = 1;

        for await (const config of jsonData){

            await onSaveConfiguracionPartidas(config)

            setPorcentaje(((contador / size_array) * 100).toFixed(2))

            contador += 1;

        }
    }

    const exportExcel = () => {
        import('xlsx').then((xlsx) => {
            const worksheet = xlsx.utils.json_to_sheet(catalogo);
            const workbook = { Sheets: { data: worksheet }, SheetNames: ['data'] };
            const excelBuffer = xlsx.write(workbook, {
                bookType: 'xlsx',
                type: 'array'
            });

            saveAsExcelFile(excelBuffer, 'CatalogoCuentas');
        });
    };

    const saveAsExcelFile = (buffer, fileName) => {
        import('file-saver').then((module) => {
            if (module && module.default) {
                let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
                let EXCEL_EXTENSION = '.xlsx';
                const data = new Blob([buffer], {
                    type: EXCEL_TYPE
                });

                module.default.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
            }
        });
    };

    return (
        <>
            <div className="grid">
                <div className="col-12">
                    <div className="card">
                        <h4>Porcentaje</h4>
                        <ProgressBar value={porcentaje}></ProgressBar>
                    </div>
                </div>
            </div>

            <div className="grid">
                <div className="col-8">
                    <div className="card">
                        <h4>Indicaciones</h4>
                        <p>Para poder subir las configuraciones de las partidas utiliza la plantilla indicada, puedes auxiliarte de los documentos anexados a continuacion</p>

                        <div className="grid">
                            <div className="col-6">
                                <Button className="bg-bluegray-600 hover:bg-bluegray-400 border-bluegray-700">
                                    <Image src={excel_icon} alt="icon_excel" width="100" />
                                    <h4>Plantilla Configuracion</h4>
                                </Button>
                            </div>
                            <div className="col-6">
                                <Button className="bg-bluegray-600 hover:bg-bluegray-400 border-bluegray-700" onClick={exportExcel}>
                                    <Image src={excel_icon} alt="icon_excel" width="100" />
                                    <h4>Catalogo de Cuenta</h4>
                                </Button>
                            </div>
                        </div>

                    </div>
                </div>
                <div className="col-4">
                    <div className="card">
                        <FileUpload name="FileConfiguration" url={`${BASE_API}v1/FormatPlantillaConfiguracion/`} accept="xls" maxFileSize={1000000} emptyTemplate={<p className="m-0">Drag and drop files to here to upload.</p>} onUpload={onUpload} />
                    </div>
                </div>
            </div>
        </>
    )
}